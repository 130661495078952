import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ModelDistributionOverTimeChart = ({ data }) => {
  if (!data) return <p>No Model Distribution Over Time Data</p>;

  return (
    <div className="chart-container">
      <h4>Model Distribution Over Time</h4>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          {data.length > 0 &&
            Object.keys(data[0])
              .filter((key) => key !== 'date')
              .map((model, index) => (
                <Line
                  key={model}
                  type="monotone"
                  dataKey={model}
                  stroke={['#8884d8', '#82ca9d', '#ff7300', '#0088FE'][index % 4]}
                />
              ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ModelDistributionOverTimeChart;
