import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const OverTimeReviewsChart = ({ reviewHistory }) => {
  if (!reviewHistory || reviewHistory.length === 0)
    return <p>No review history available</p>;

  return (
    <div className="chart-container">
      <h4>Reviews Over Time</h4>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={reviewHistory}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          {/* Assign an ID to the left YAxis */}
          <YAxis
            yAxisId="left"
            label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
            allowDecimals={false}
          />
          {/* Assign an ID to the right YAxis */}
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{ value: 'Rating', angle: 90, position: 'insideRight' }}
            domain={[0, 5]}
          />
          <Tooltip />
          <Legend />
          {/* Lines for review counts use the left axis */}
          <Line
            type="monotone"
            dataKey="google"
            stroke="#8884d8"
            name="Google Count"
            yAxisId="left"
          />
          <Line
            type="monotone"
            dataKey="yelp"
            stroke="#82ca9d"
            name="Yelp Count"
            yAxisId="left"
          />
          {/* Lines for ratings use the right axis */}
          <Line
            type="monotone"
            dataKey="google_rating"
            stroke="#ff7300"
            name="Google Rating"
            yAxisId="right"
          />
          <Line
            type="monotone"
            dataKey="yelp_rating"
            stroke="#0088FE"
            name="Yelp Rating"
            yAxisId="right"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverTimeReviewsChart;
