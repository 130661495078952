// dealerdatatable.js
import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import { flattenObject } from './helpers';
import LoadingSpinner from './LoadingSpinner';
import TimeFilters from './TimeFilters';
import { Sparklines, SparklinesLine } from 'react-sparklines';

// Import icons for export and header info
import TableChartIcon from '@mui/icons-material/TableChart';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';

// Import XLSX for Excel export
import * as XLSX from 'xlsx';

const COLUMN_DISPLAY_NAMES = {
  id: "ID",
  dealership_name: "Dealership Name",
  listing_date: "Listing Date",
  listing_type: "Listing Type",
  page_index: "Page Index",
  heading: "Heading",
  price: "Price",
  year: "Year",
  vin: "VIN",
  stock_number: "Stock Number",
  mileage: "Mileage",
  transmission: "Transmission",
  exterior_color: "Exterior Color",
  interior_color: "Interior Color",
  color: "Color",
  trim: "Trim",
  drivetrain: "Drivetrain",
  detail_link: "Detail Link",
  vehicle_condition: "Vehicle Condition",
  text: "Text",
  flag: "Flag",
  make: "Make",
  model: "Model",
  engine: "Engine",
  drive_type: "Drive Type",
  body_style: "Body Style",
  created_at: "Created At",
  last_date_seen: "Last Seen",
  // Additional headers for extra columns:
  price_chart: "Price Chart",
  page_chart: "Page Chart",
  screenshot: "Screenshot"
};

// Mapping header keys to extra info text.
const HEADER_INFO = {
  listing_date: "The date the listing was posted.",
  listing_type: "Type of listing (New, Used, Certified).",
  detail_link: "Link to the detailed listing page.",
  price: "Price of the vehicle.",
  year: "Year of manufacture.",
  make: "Brand of the vehicle.",
  model: "Model of the vehicle.",
  vin: "Vehicle Identification Number.",
  stock_number: "Stock number provided by the dealer.",
  mileage: "Mileage of the vehicle.",
  vehicle_condition: "Vehicle condition (e.g., New, Used, Certified).",
  flag: "Live flag indicating if the listing is currently active.",
  transmission: "Transmission type.",
  exterior_color: "Exterior color of the vehicle.",
  interior_color: "Interior color of the vehicle.",
  color: "Color details.",
  trim: "Trim details.",
  drivetrain: "Drivetrain type.",
  engine: "Engine specifications.",
  drive_type: "Drive type (e.g., FWD, RWD, AWD).",
  body_style: "Body style of the vehicle.",
  created_at: "Record creation date.",
  last_date_seen: "Last seen date. 'Live' indicates an active listing.",
  heading: "Listing heading or title.",
  page_index: "Page index in the pagination history.",
  price_chart: "A mini-chart displaying the vehicle's price history.",
  page_chart: "A mini-chart displaying the pagination history of the listing.",
  screenshot: "A button that opens a screenshot of the listing."
};

const getColorFromName = (colorName) => {
  if (!colorName) return 'transparent';
  const lower = colorName.toLowerCase();

  // Common color keywords
  if (lower.includes('black')) return "#000000";
  if (lower.includes('white')) return "#ffffff";
  if (lower.includes('gray') || lower.includes('grey')) return "#808080";
  if (lower.includes('red')) return "#ff0000";
  if (lower.includes('blue')) return "#0000ff";
  if (lower.includes('green')) return "#008000";
  if (lower.includes('silver')) return "#c0c0c0";
  if (lower.includes('gold')) return "#ffd700";
  if (lower.includes('brown')) return "#a52a2a";

  // Specific keywords from your examples or similar descriptive names
  if (lower.includes('celestite')) return "#b0c4de";       // Example: Light Steel Blue
  if (lower.includes('caviar')) return "#1a1a1a";           // Example: Dark, almost black
  if (lower.includes('midnight')) return "#191970";         // Example: Midnight Blue
  if (lower.includes('predawn')) return "#696969";          // Example: Dim Gray

  // Fallback default color
  return '#ccc';
};


const DealerDataTable = ({
  selectedDealer,
  selectedTimeFilter,
  setSelectedDealer,
  setSelectedTimeFilter,
  token // pass token if needed
}) => {
  const [listings, setListings] = useState([]);
  const [loadingListings, setLoadingListings] = useState(true);
  const [filterCondition, setFilterCondition] = useState([]);
  const [filterPriceRange, setFilterPriceRange] = useState([0, 100000]);
  const [filterMake, setFilterMake] = useState([]);
  const [filterModel, setFilterModel] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [exportOpen, setExportOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // New state for header info dialog
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [currentHeaderTitle, setCurrentHeaderTitle] = useState('');
  const [currentHeaderInfo, setCurrentHeaderInfo] = useState('');

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Fetch listings on mount or when selectedDealer / time filter changes
  useEffect(() => {
    async function fetchListings() {
      setLoadingListings(true);
      try {
        const response = await fetch(
          `/.netlify/functions/listings?dealership=${encodeURIComponent(selectedDealer)}&timeFilter=${selectedTimeFilter}`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' }
          }
        );
        if (!response.ok) {
          throw new Error('Listings network error');
        }
        const data = await response.json();
        setListings(data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      } finally {
        setLoadingListings(false);
      }
    }
    if (selectedDealer) {
      fetchListings();
    }
  }, [selectedDealer, selectedTimeFilter, token]);

  // Apply filters and search across all columns
  const filteredListings = listings.filter(listing => {
    if (filterCondition.length > 0 && !filterCondition.includes(listing.vehicle_condition)) {
      return false;
    }
    const rawPrice = listing.price ? listing.price.toString() : '';
    const cleanedPrice = rawPrice.replace(/[^0-9.]/g, '');
    const price = parseFloat(cleanedPrice);
    if (isNaN(price) || price < filterPriceRange[0] || price > filterPriceRange[1]) {
      return false;
    }
    if (filterMake.length > 0 && !filterMake.includes(listing.make)) {
      return false;
    }
    if (filterModel.length > 0 && !filterModel.includes(listing.model)) {
      return false;
    }
    // Global search: check if any flattened field contains the search term
    if (searchTerm) {
      const flatListing = flattenObject(listing);
      const combined = Object.values(flatListing).join(' ').toLowerCase();
      if (!combined.includes(searchTerm.toLowerCase())) {
        return false;
      }
    }
    return true;
  });

  // Sorting logic
  let sortedListings = [...filteredListings];
  if (sortConfig.key) {
    sortedListings.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }

  // Apply pagination on sorted listings
  const displayedListings = sortedListings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Define desired header order
  const desiredHeaderOrder = [
    'listing_date',
    'listing_type',
    'detail_link',
    'price',
    'year',
    'make',
    'model',
    'vin',
    'stock_number',
    'mileage',
    'vehicle_condition',
    'flag',
    'transmission',
    'exterior_color',
    'interior_color',
    'color',
    'trim',
    'drivetrain',
    'engine',
    'drive_type',
    'body_style',
    'created_at',
    'last_date_seen',
    'heading',
    'page_index'
  ];

  // Compute headers to display in desired order (filtering out unwanted keys)
  const baseHeaders = sortedListings.length > 0 ? Object.keys(flattenObject(sortedListings[0])) : [];
  const filteredHeaders = baseHeaders.filter(header => !['text', 'id', 'price_history', 'pagination_history', 'screenshot_link'].includes(header));
  const orderedHeaders = desiredHeaderOrder.filter(header => filteredHeaders.includes(header));

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Open the info dialog for a header.
  const openInfoDialog = (header) => {
    setCurrentHeaderTitle(COLUMN_DISPLAY_NAMES[header] || header);
    setCurrentHeaderInfo(HEADER_INFO[header] || "No additional info available.");
    setInfoDialogOpen(true);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Helper functions for formatting cells
  const formatPrice = (val) => {
    const parsed = parseFloat(val.toString().replace(/[^0-9.]/g, ''));
    return isNaN(parsed)
      ? 'N/A'
      : parsed.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
  };

  const formatMileage = (val) => {
    const parsed = parseInt(val.toString().replace(/[^0-9]/g, ''), 10);
    return isNaN(parsed)
      ? 'N/A'
      : parsed.toLocaleString() + ' mi';
  };

  const renderCellContent = (header, flatListing) => {
    const value = flatListing[header];
    if (header === 'detail_link') {
      const url = value;
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={() => window.open(url, '_blank')}
            variant="contained"
            color="primary"
            size="small"
          >
            Open Link
          </Button>
          <Button variant="outlined" size="small">
            Copy Link
          </Button>
        </div>
      );
    } else if (header === 'listing_date' || header === 'created_at') {
      return value ? value.slice(0, 10) : 'N/A';
    } else if (header === 'last_date_seen') {
      // Distinguish "Live" listings with a special style
      return value === null ? (
        <span style={{ backgroundColor: '#d4edda', fontWeight: 'bold', padding: '2px 4px', borderRadius: '4px' }}>
          Live
        </span>
      ) : (
        value.slice(0, 10)
      );
    } else if (header === 'price') {
      return value ? formatPrice(value) : 'N/A';
    } else if (header === 'mileage') {
      return value ? formatMileage(value) : 'N/A';
    } else if (header === 'exterior_color' || header === 'interior_color') {
      const circleColor = getColorFromName(value);
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: circleColor,
              border: '1px solid #ccc'
            }}
          ></div>
          <span>{value}</span>
        </div>
      );
    } else if (header === 'vehicle_condition') {
      const condition = value ? value.toString().toLowerCase() : '';
      let bgColor = '';
      if (condition === 'new') bgColor = '#d4edda';
      else if (condition === 'used') bgColor = '#fff3cd';
      else if (condition === 'certified') bgColor = '#cce5ff';
      return (
        <span style={{ backgroundColor: bgColor, padding: '2px 4px', borderRadius: '4px' }}>
          {value}
        </span>
      );
    } else if (header === 'flag') {
      const flagVal = value ? value.toString().toLowerCase() : '';
      return flagVal === 'yes' || flagVal === 'true'
        ? <FlagIcon style={{ verticalAlign: 'middle', color: 'red' }} />
        : '';
    } else if (header === 'page_index') {
      // Add a light background to page_index values
      return (
        <span style={{ backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '4px' }}>
          {value !== null && value !== undefined ? value.toString() : 'N/A'}
        </span>
      );
    } else {
      return value !== null && value !== undefined ? value.toString() : 'N/A';
    }
  };
  
  
  return (
    <div className="dealer-detail">
      <button className="back-button" onClick={() => setSelectedDealer(null)}>
        &larr; Back to Overview
      </button>
      <h2>{selectedDealer} Listings</h2>
      <p>
        Detailed listings for <strong>{selectedDealer}</strong> based on the "{selectedTimeFilter}" filter.
      </p>
      <TimeFilters 
        selectedTimeFilter={selectedTimeFilter} 
        onSelectTime={setSelectedTimeFilter} 
      />
      <div className="table-actions" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', flexWrap: 'wrap', gap: '10px' }}>
        <TextField 
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ minWidth: '200px' }}
        />
        <Button
          onClick={() => setExportOpen(true)}
          variant="contained"
          color="primary"
          style={{ marginLeft: '20px' }}
        >
          Export Data
        </Button>
      </div>
      <div className="additional-filters" style={{ marginBottom: '20px', display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
          <InputLabel>Vehicle Condition</InputLabel>
          <Select
            multiple
            value={filterCondition}
            onChange={(e) => setFilterCondition(e.target.value)}
            input={<OutlinedInput label="Vehicle Condition" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {[...new Set(listings.map(listing => listing.vehicle_condition).filter(Boolean))].map((cond) => (
              <MenuItem key={cond} value={cond}>
                <Checkbox checked={filterCondition.indexOf(cond) > -1} />
                {cond}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
          <InputLabel>Make</InputLabel>
          <Select
            multiple
            value={filterMake}
            onChange={(e) => setFilterMake(e.target.value)}
            input={<OutlinedInput label="Make" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {[...new Set(listings.map(listing => listing.make).filter(Boolean))].map((make) => (
              <MenuItem key={make} value={make}>
                <Checkbox checked={filterMake.indexOf(make) > -1} />
                {make}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
          <InputLabel>Model</InputLabel>
          <Select
            multiple
            value={filterModel}
            onChange={(e) => setFilterModel(e.target.value)}
            input={<OutlinedInput label="Model" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {[...new Set(listings.map(listing => listing.model).filter(Boolean))].map((model) => (
              <MenuItem key={model} value={model}>
                <Checkbox checked={filterModel.indexOf(model) > -1} />
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ width: 300 }}>
          <InputLabel shrink>Price Range</InputLabel>
          <Slider
            value={filterPriceRange}
            onChange={(e, newValue) => setFilterPriceRange(newValue)}
            valueLabelDisplay="auto"
            min={0}
            max={100000}
          />
        </div>
      </div>
      {loadingListings ? (
        <LoadingSpinner />
      ) : sortedListings.length > 0 ? (
        <>
          <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
            <Table style={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {orderedHeaders.map((header) => (
                    <TableCell
                      key={header}
                      onClick={() => handleSort(header)}
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        backgroundColor: '#666',
                        color: '#fff',
                        textOverflow: 'ellipsis',
                        padding: '4px 8px',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{COLUMN_DISPLAY_NAMES[header] || header}</span>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            openInfoDialog(header);
                          }}
                        >
                          <InfoIcon fontSize="small" style={{ color: '#fff' }} />
                        </IconButton>
                      </div>
                    </TableCell>
                  ))}
                  {/* Additional columns with info buttons */}
                  {["price_chart", "page_chart", "screenshot"].map((colKey) => (
                    <TableCell
                      key={colKey}
                      style={{ fontWeight: 'bold', backgroundColor: '#666', color: '#fff', padding: '4px 8px', whiteSpace: 'nowrap' }}
                      onClick={() => handleSort(colKey)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{COLUMN_DISPLAY_NAMES[colKey]}</span>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            openInfoDialog(colKey);
                          }}
                        >
                          <InfoIcon fontSize="small" style={{ color: '#fff' }} />
                        </IconButton>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedListings.map((listing, idx) => {
                  const flatListing = flattenObject(listing);
                  return (
                    <TableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? '#f5f5f5' : 'inherit' }}>
                      {orderedHeaders.map((header) => (
                        <TableCell
                          key={header}
                          style={{
                            padding: '4px 8px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                          }}
                        >
                          {renderCellContent(header, flatListing)}
                        </TableCell>
                      ))}
                      {/* Price History Minichart */}
                      <TableCell style={{ padding: '4px 8px', whiteSpace: 'nowrap' }}>
                        {listing.price_history && listing.price_history.length > 0 ? (() => {
                          const priceData = listing.price_history.map(item => parseFloat(item.price.replace(/[^0-9.]/g, '')));
                          if (priceData.length === 1) {
                            return (
                              <div style={{ width: 100, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: 6, height: 6, borderRadius: '50%', backgroundColor: '#6363ff' }}></div>
                              </div>
                            );
                          }
                          return (
                            <Sparklines data={priceData} width={100} height={20}>
                              <SparklinesLine color="blue" />
                            </Sparklines>
                          );
                        })() : (
                          'N/A'
                        )}
                      </TableCell>
                      {/* Pagination History Minichart */}
                      <TableCell style={{ padding: '4px 8px', whiteSpace: 'nowrap' }}>
                        {listing.pagination_history && listing.pagination_history.length > 0 ? (() => {
                          const pageData = listing.pagination_history.map(item => item.page_index);
                          if (pageData.length === 1) {
                            return (
                              <div style={{ width: 100, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: 6, height: 6, borderRadius: '50%', backgroundColor: '#77ae70' }}></div>
                              </div>
                            );
                          }
                          return (
                            <Sparklines data={pageData} width={100} height={20}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          );
                        })() : (
                          'N/A'
                        )}
                      </TableCell>
                      {/* Screenshot Button */}
                      <TableCell style={{ padding: '4px 8px', whiteSpace: 'nowrap' }}>
                        {listing.screenshot_link ? (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => window.open(listing.screenshot_link, '_blank')}
                          >
                            View Screenshot
                          </Button>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={sortedListings.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </>
      ) : (
        <p>No listings found for {selectedDealer} with the applied filters.</p>
      )}
      <DealerExportDialog
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        token={token}
        selectedDealer={selectedDealer}
      />

      {/* Info Dialog */}
      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogTitle>{currentHeaderTitle} Info</DialogTitle>
        <DialogContent>
          <p>{currentHeaderInfo}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DealerExportDialog = ({ open, onClose, token, selectedDealer }) => {
  const [exportOption, setExportOption] = useState('live');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [exportConditions, setExportConditions] = useState([]);
  const [exportMakes, setExportMakes] = useState([]);
  const [exportModels, setExportModels] = useState([]);
  const [availableConditions, setAvailableConditions] = useState([]);
  const [availableMakes, setAvailableMakes] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [exporting, setExporting] = useState(false);

  const [exportFormat, setExportFormat] = useState('csv');
  const today = new Date().toISOString().slice(0, 10);
  const [exportFileName, setExportFileName] = useState(`MotorMetrics-ListingsData-${today}.csv`);

  // Update file extension when exportFormat changes
  useEffect(() => {
    setExportFileName(prevFileName => {
      const ext = exportFormat === 'excel' ? 'xlsx' : exportFormat === 'json' ? 'json' : 'csv';
      const base = prevFileName.lastIndexOf('.') !== -1 ? prevFileName.substring(0, prevFileName.lastIndexOf('.')) : prevFileName;
      const newFileName = `${base}.${ext}`;
      return newFileName;
    });
  }, [exportFormat]);

  // Fetch listings for the single selected dealer
  const fetchListings = useCallback(async () => {
    try {
      const res = await fetch(
        `/.netlify/functions/listings?dealership=${encodeURIComponent(selectedDealer)}&timeFilter=1M`,
        {
          headers: { Authorization: token ? `Bearer ${token}` : '' }
        }
      );
      if (res.ok) {
        const data = await res.json();
        return data;
      }
    } catch (err) {
      console.error(`Error fetching listings for dealer ${selectedDealer}:`, err);
    }
    return [];
  }, [selectedDealer, token]);

  // When the dialog opens, load available filter options from the listings.
  useEffect(() => {
    if (open) {
      (async () => {
        const listings = await fetchListings();
        const conditions = [...new Set(listings.map(l => l.vehicle_condition).filter(Boolean))];
        const makes = [...new Set(listings.map(l => l.make).filter(Boolean))];
        const models = [...new Set(listings.map(l => l.model).filter(Boolean))];
        setAvailableConditions(conditions);
        setAvailableMakes(makes);
        setAvailableModels(models);
      })();
    }
  }, [open, fetchListings]);

  // Utility: Convert data to CSV and trigger download
  const exportToCsv = (data, filename) => {
    if (!data || !data.length) {
      console.warn("No data to export");
      return;
    }
    const headers = Object.keys(flattenObject(data[0])).filter(h => h !== 'text' && h !== 'id');
    const csvRows = [];
    csvRows.push(headers.join(','));
    data.forEach(row => {
      const flatRow = flattenObject(row);
      const values = headers.map(header => {
        let val = flatRow[header] !== null && flatRow[header] !== undefined ? flatRow[header].toString() : '';
        val = val.replace(/"/g, '""');
        return `"${val}"`;
      });
      csvRows.push(values.join(','));
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Handle export: fetch listings, filter them, then export in the selected format.
  const handleExportClick = async () => {
    setExporting(true);
    let listings = await fetchListings();
    let filteredForExport = listings.filter(listing => {
      if (exportOption === 'live') {
        if (listing.last_date_seen !== null) return false;
      } else if (exportOption === 'this_week') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const now = new Date();
        const day = now.getDay();
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - day);
        if (listingDate < startOfWeek) return false;
      } else if (exportOption === 'this_month') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        if (listingDate < startOfMonth) return false;
      } else if (exportOption === 'this_year') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        if (listingDate < startOfYear) return false;
      } else if (exportOption === 'custom') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        const start = customStartDate ? new Date(customStartDate) : null;
        const end = customEndDate ? new Date(customEndDate) : null;
        if (start && listingDate && listingDate < start) return false;
        if (end && listingDate && listingDate > end) return false;
      }
      if (exportConditions.length > 0 && !exportConditions.includes(listing.vehicle_condition)) {
        return false;
      }
      if (exportMakes.length > 0 && !exportMakes.includes(listing.make)) {
        return false;
      }
      if (exportModels.length > 0 && !exportModels.includes(listing.model)) {
        return false;
      }
      return true;
    });

    if (exportFormat === 'csv') {
      exportToCsv(filteredForExport, exportFileName);
    } else if (exportFormat === 'json') {
      const jsonString = JSON.stringify(filteredForExport, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = exportFileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (exportFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredForExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listings');
      XLSX.writeFile(wb, exportFileName);
    }

    setExporting(false);
    onClose();
  };

  // Base style for export option container
  const optionBaseStyle = {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '8px',
    borderRadius: '4px',
    position: 'relative',
    flex: 1,
    margin: '0 8px'
  };

  // Get additional style if option is selected
  const getOptionStyle = (option) => {
    if (exportFormat === option) {
      return { backgroundColor: '#d0eaff', border: '2px solid blue' };
    }
    return { backgroundColor: '#f5f5f5' };
  };

  // Render export option component
  const renderExportOption = (option, icon, label) => (
    <div
      style={{ ...optionBaseStyle, ...getOptionStyle(option) }}
      onClick={() => setExportFormat(option)}
    >
      {icon}
      <div>{label}</div>
      {exportFormat === option && (
        <CheckCircleIcon style={{ color: 'blue', position: 'absolute', top: 4, right: 4 }} />
      )}
    </div>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Export Listings Data</DialogTitle>
      <DialogContent dividers>
        {/* Export option selection */}
        <div style={{ margin: '12px 0 0px 0', fontWeight: 'bold', fontSize: '16px' }}>Select Timeframe</div>
        <FormControl component="fieldset" margin="normal">
          <RadioGroup row value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
            <FormControlLabel value="live" control={<Radio />} label="Live" />
            <FormControlLabel value="this_week" control={<Radio />} label="This Week" />
            <FormControlLabel value="this_month" control={<Radio />} label="This Month" />
            <FormControlLabel value="this_year" control={<Radio />} label="This Year" />
            <FormControlLabel value="custom" control={<Radio />} label="Custom Date" />
          </RadioGroup>
        </FormControl>

        {exportOption === 'custom' && (
          <>
            <TextField
              label="Start Date & Time"
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
            <TextField
              label="End Date & Time"
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
          </>
        )}

        {/* Additional filters */}
        <div style={{ margin: '0', fontWeight: 'bold', fontSize: '16px' }}>Select Data Filters</div>
        <FormControl fullWidth margin="normal">
          <InputLabel>Vehicle Condition</InputLabel>
          <Select
            multiple
            value={exportConditions}
            onChange={(e) =>
              setExportConditions(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Vehicle Condition" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableConditions.map((cond) => (
              <MenuItem key={cond} value={cond}>
                <Checkbox checked={exportConditions.indexOf(cond) > -1} />
                {cond}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Make</InputLabel>
          <Select
            multiple
            value={exportMakes}
            onChange={(e) =>
              setExportMakes(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Make" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableMakes.map((make) => (
              <MenuItem key={make} value={make}>
                <Checkbox checked={exportMakes.indexOf(make) > -1} />
                {make}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Model</InputLabel>
          <Select
            multiple
            value={exportModels}
            onChange={(e) =>
              setExportModels(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Model" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableModels.map((model) => (
              <MenuItem key={model} value={model}>
                <Checkbox checked={exportModels.indexOf(model) > -1} />
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Export Format Selection */}
        <div style={{ margin: '16px 0', fontWeight: 'bold', fontSize: '16px' }}>Select Export Type</div>
        <div className="export-format-selector" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '16px' }}>
          {renderExportOption('excel', <TableChartIcon style={{ fontSize: '40px' }} />, 'Excel')}
          {renderExportOption('csv', <DescriptionIcon style={{ fontSize: '40px' }} />, 'CSV')}
          {renderExportOption('json', <CodeIcon style={{ fontSize: '40px' }} />, 'JSON')}
        </div>

        {/* Export File Name */}
        <TextField
          label="Export File Name"
          fullWidth
          margin="normal"
          value={exportFileName}
          onChange={(e) => setExportFileName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={exporting}>Cancel</Button>
        <Button onClick={handleExportClick} variant="contained" color="primary" disabled={exporting}>
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealerDataTable;
