import React from 'react';
import './DealershipTile.css';

const AddDealershipTile = ({ onClick }) => {
  return (
    <div
      className="dealer-card add-dealership-tile"
      onClick={onClick}
      style={{
        border: '2px dashed #aaa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#f9f9f9'
      }}
    >
      <h3>Add Dealership</h3>
    </div>
  );
};

export default AddDealershipTile;
