// InventoryMapComponent.js
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  useMap,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './InventoryMapComponent.css';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse, faDollarSign, faRoad } from '@fortawesome/free-solid-svg-icons';
import StockHistoryChart from './StockHistoryChart';

// Fix Leaflet icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Custom Option with a checkbox for multi-select
const CheckboxOption = (props) => (
  <components.Option {...props}>
    <input 
      type="checkbox" 
      checked={props.isSelected} 
      onChange={() => null} 
      style={{ marginRight: 8 }}
    />
    <label>{props.label}</label>
  </components.Option>
);

// Reusable select component
const CustomSelect = ({ label, options, value, onChange, isMulti = false, isClearable = false }) => {
  return (
    <div className="custom-select-container">
      <label className="custom-select-label">{label}</label>
      <Select
        isMulti={isMulti}
        isClearable={isClearable}
        options={options}
        value={value}
        onChange={onChange}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={!isMulti}
        components={isMulti ? { Option: CheckboxOption } : {}}
        className="custom-select"
        classNamePrefix="react-select"
      />
    </div>
  );
};

// Fit the map bounds to the markers
const MapBounds = ({ markers }) => {
  const map = useMap();
  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((m) => [Number(m.latitude), Number(m.longitude)])
      );
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers, map]);
  return null;
};

// Color interpolation based on stock count
const interpolateColor = (value, min, max) => {
  if (value == null || max === min) return '#FFEDA0';
  const normalized = (value - min) / (max - min);
  const start = { r: 255, g: 237, b: 160 };
  const end = { r: 128, g: 0, b: 38 };
  const r = Math.round(start.r + normalized * (end.r - start.r));
  const g = Math.round(start.g + normalized * (end.g - start.g));
  const b = Math.round(start.b + normalized * (end.b - start.b));
  return `rgb(${r}, ${g}, ${b})`;
};

// InventoryMarker accepts an array of items (for a given location)
// and renders a carousel if there are multiple models.
const InventoryMarker = ({
  items,
  minStock,
  maxStock,
  zoomThreshold = 10,
  tolerance = 50,
}) => {
  const map = useMap();
  const markerRef = React.useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = items[currentIndex];

  useEffect(() => {
    const handleZoom = () => {
      if (!markerRef.current) return;
      const currentZoom = map.getZoom();
      const markerLatLng = markerRef.current.getLatLng();
      const markerPoint = map.latLngToContainerPoint(markerLatLng);
      const mapSize = map.getSize();
      const isWithinTolerance =
        markerPoint.x > tolerance &&
        markerPoint.x < mapSize.x - tolerance &&
        markerPoint.y > tolerance &&
        markerPoint.y < mapSize.y - tolerance;

      if (currentZoom >= zoomThreshold && isWithinTolerance) {
        markerRef.current.openPopup();
      } else {
        markerRef.current.closePopup();
      }
    };

    map.on('zoomend moveend resize', handleZoom);
    handleZoom();
    return () => {
      map.off('zoomend moveend resize', handleZoom);
    };
  }, [map, zoomThreshold, tolerance]);

  const fillColor = interpolateColor(currentItem.stock_count, minStock, maxStock);

  return (
    <CircleMarker
      ref={markerRef}
      center={[Number(currentItem.latitude), Number(currentItem.longitude)]}
      radius={10}
      fillColor={fillColor}
      color={fillColor}
      fillOpacity={0.8}
      stroke={false}
    >
      <Popup autoPan={false} autoClose={false} closeOnClick={false}>
        <div className="popup-card">
          <div className="card-header">
            <div className="card-title">
              {currentItem.dealership_name}
            </div>
            {items.length > 1 && (
              <div className="carousel-container">
                <div className="carousel-controls">
                  <button
                    onClick={() =>
                      setCurrentIndex((currentIndex - 1 + items.length) % items.length)
                    }
                  >
                    ◀
                  </button>
                  <span>{`${currentIndex + 1} / ${items.length}`}</span>
                  <button
                    onClick={() => setCurrentIndex((currentIndex + 1) % items.length)}
                  >
                    ▶
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="make-model">
              <p>
                <strong>
                  {currentItem.make} {currentItem.model}
                </strong>
              </p>
            </div>
            <div className="metrics">
              <div className="metric">
                <FontAwesomeIcon icon={faWarehouse} className="icon" />
                <span className="metric-label">Stock: </span>
                <span>{currentItem.stock_count}</span>
              </div>
              <div className="metric">
                <FontAwesomeIcon icon={faDollarSign} className="icon" />
                <span className="metric-label">Avg Price: </span>
                <span>${Number(currentItem.avg_price).toFixed(2)}</span>
              </div>
              <div className="metric">
                <FontAwesomeIcon icon={faRoad} className="icon" />
                <span className="metric-label">Avg Mileage: </span>
                <span>{Number(currentItem.avg_mileage).toFixed(0)} mi</span>
              </div>
            </div>
            <div className="chart-container">
              {/*
                Normalize dealership value to match DB format by replacing spaces with underscores.
              */}
              {(() => {
                const normalizedDealership = currentItem.dealership_name.replace(/ /g, '_');
                return (
                  <StockHistoryChart
                    dealership={normalizedDealership}
                    make={currentItem.make}
                    model={currentItem.model}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </Popup>
    </CircleMarker>
  );
};

const InventoryMapComponent = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [sortOrder, setSortOrder] = useState({ value: 'desc', label: 'Descending' });
  const { token } = useAuth();

  // Options for sort order
  const sortOptions = [
    { value: 'desc', label: 'Descending' },
    { value: 'asc', label: 'Ascending' },
  ];

  // Fetch aggregated listings data when filters change (allowing filters to be cleared)
  useEffect(() => {
    const controller = new AbortController();
    const timer = setTimeout(() => {
      const fetchData = async () => {
        try {
          const params = new URLSearchParams();
          if (selectedMake && selectedMake.value) {
            params.append('make', selectedMake.value);
          }
          if (selectedModel && selectedModel.value) {
            params.append('model', selectedModel.value);
          }
          const queryString = params.toString() ? `?${params.toString()}` : '';
          const response = await fetch(`/.netlify/functions/inventory${queryString}`, {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          });
          if (!response.ok) throw new Error('Failed to fetch inventory data');
          const data = await response.json();
        // Filter out any records missing dealership, make, or model
        const validData = data.filter(
          (item) => item.dealership_name && item.make && item.model
        );
        setInventoryData(validData);
        setLoading(false);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching inventory data:', error);
          setLoading(false);
        }
      };
      fetchData();
    }, 300);

    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [token, selectedMake, selectedModel]);

  // Build unique options for makes and models from the data
  const makeOptions = Array.from(new Set(inventoryData.map((item) => item.make))).map((make) => ({
    value: make,
    label: make,
  }));
  const modelOptions = Array.from(new Set(inventoryData.map((item) => item.model)))
  .filter((model) => model !== null)
  .map((model) => ({
    value: model,
    label: model,
  }));

  modelOptions.sort((a, b) => a.label.localeCompare(b.label));

  // Filter data based on selected filters
  let filteredData = inventoryData;
  if (selectedMake && selectedMake.value) {
    filteredData = filteredData.filter((item) => item.make === selectedMake.value);
  }
  if (selectedModel && selectedModel.value) {
    filteredData = filteredData.filter((item) => item.model === selectedModel.value);
  }

  // Sort by stock count
  filteredData.sort((a, b) => {
    const diff = a.stock_count - b.stock_count;
    return sortOrder.value === 'asc' ? diff : -diff;
  });

  // Determine min and max stock for marker color scaling
  const stockValues = filteredData.map((item) => Number(item.stock_count));
  const minStock = stockValues.length > 0 ? Math.min(...stockValues) : 0;
  const maxStock = stockValues.length > 0 ? Math.max(...stockValues) : 0;

  // Group data by dealership/location (using dealership name and coordinates as key)
  const groupedData = filteredData.reduce((acc, item) => {
    const key = `${item.dealership_name}-${item.latitude}-${item.longitude}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return (
    <div className="map-component-container">
      <div className="filters-container">
        <CustomSelect
          label="Select Make:"
          options={makeOptions}
          value={selectedMake}
          onChange={(option) => setSelectedMake(option)}
          isClearable={true}
        />
        <CustomSelect
          label="Select Model:"
          options={modelOptions}
          value={selectedModel}
          onChange={(option) => setSelectedModel(option)}
          isClearable={true}
        />
        <CustomSelect
          label="Sort Order:"
          options={sortOptions}
          value={sortOrder}
          onChange={(option) => setSortOrder(option)}
        />
      </div>
      <div className="map-container">
        {loading ? (
          <div className="map-loading-spinner">
            <div className="spinner"></div>
            <p>Loading inventory data...</p>
          </div>
        ) : (
          <MapContainer center={[39.5, -98.35]} zoom={4} className="leaflet-map">
            <TileLayer
              attribution='&copy; <a href="https://carto.com/">Carto</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
            />
            <MapBounds markers={filteredData} />
            {Object.values(groupedData).map((group) => (
              <InventoryMarker
                key={`${group[0].dealership_name}-${group[0].latitude}-${group[0].longitude}`}
                items={group}
                minStock={minStock}
                maxStock={maxStock}
                zoomThreshold={6}
              />
            ))}
          </MapContainer>
        )}
      </div>
    </div>
  );
};

export default InventoryMapComponent;
