import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import LoadingSpinner from './LoadingSpinner';

function AdminPanel() {
  const { token, role, loading } = useAuth();
  
  // Loading & message states
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  // Dealerships
  const [dealerships, setDealerships] = useState([]);
  const [isEditingDefaults, setIsEditingDefaults] = useState(false);
  const [editedDealerships, setEditedDealerships] = useState([]);

  // Users & Permissions
  const [usersList, setUsersList] = useState([]);            
  const [userPermissions, setUserPermissions] = useState([]); 
  const [combinedUsers, setCombinedUsers] = useState([]); 
  const [editedUsers, setEditedUsers] = useState([]);     
  const [isEditingUsers, setIsEditingUsers] = useState(false);

  // Role Caps (NO ID in DB)
  const [roleCaps, setRoleCaps] = useState([]);          // Original data from DB
  const [editedRoleCaps, setEditedRoleCaps] = useState([]); // In-progress edits
  const [isEditingRoleCaps, setIsEditingRoleCaps] = useState(false);

  /**
   * -----------------------------
   * Fetch Dealerships
   * -----------------------------
   */
  const fetchDealerships = useCallback(async () => {
    try {
      const response = await axios.get('/.netlify/functions/all_dealerships', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data.map((d) => ({
        ...d,
        is_default: d.is_default === true || d.is_default === 'true',
      }));
      setDealerships(data);
      setEditedDealerships(data);
    } catch (error) {
      console.error('Error fetching dealerships:', error);
      setMessage('Error fetching dealerships.');
    }
  }, [token]);

  /**
   * -----------------------------
   * Fetch Users (with roles)
   * -----------------------------
   */
  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get('/.netlify/functions/getUsers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsersList(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage('Error fetching users.');
    }
  }, [token]);

  /**
   * -----------------------------
   * Fetch User Permissions
   * -----------------------------
   */
  const fetchUserPermissions = useCallback(async () => {
    try {
      const response = await axios.get('/.netlify/functions/getUserPermissions', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserPermissions(response.data);
    } catch (error) {
      console.error('Error fetching user permissions:', error);
      setMessage('Error fetching user permissions.');
    }
  }, [token]);

  /**
   * -----------------------------
   * Fetch Role Caps (GET /roleCaps)
   * -----------------------------
   */
  const fetchRoleCaps = useCallback(async () => {
    try {
      const response = await axios.get('/.netlify/functions/roleCaps', {
        headers: { Authorization: `Bearer ${token}` },
      });
      // response.data is an array of objects: [ {role, cap}, ... ]
      setRoleCaps(response.data);
      setEditedRoleCaps(response.data); // keep them in sync initially
    } catch (error) {
      console.error('Error fetching role caps:', error);
      setMessage('Error fetching role caps.');
    }
  }, [token]);

  /**
   * -----------------------------
   * Combined initial fetch
   * -----------------------------
   */
  useEffect(() => {
    if (!loading && role?.toLowerCase() === 'admin') {
      (async function initFetch() {
        setIsLoading(true);
        await Promise.all([
          fetchDealerships(),
          fetchUsers(),
          fetchUserPermissions(),
          fetchRoleCaps()
        ]);
        setIsLoading(false);
      })();
    }
  }, [loading, role, fetchDealerships, fetchUsers, fetchUserPermissions, fetchRoleCaps]);

  /**
   * -----------------------------
   * Merge user data: roles + permissions
   * -----------------------------
   */
  useEffect(() => {
    if (usersList.length && userPermissions.length) {
      const merged = usersList.map((user) => {
        const perms = userPermissions.find((up) => up.userId === user.id);
        return {
          userId: user.id,
          email: user.email,
          role: user.role,
          dealerships: perms ? perms.dealerships : [],
        };
      });
      setCombinedUsers(merged);
      setEditedUsers(merged);
    }
  }, [usersList, userPermissions]);

  /**
   * -----------------------------
   * Manage user editing (roles + dealerships)
   * -----------------------------
   */
  const toggleUserDealership = (userId, dealershipName, checked) => {
    setEditedUsers((prev) =>
      prev.map((u) => {
        if (u.userId === userId) {
          let updated = [...u.dealerships];
          if (checked) {
            if (!updated.includes(dealershipName)) {
              updated.push(dealershipName);
            }
          } else {
            updated = updated.filter((d) => d !== dealershipName);
          }
          return { ...u, dealerships: updated };
        }
        return u;
      })
    );
  };

  const handleRoleChange = (userId, newRole) => {
    setEditedUsers((prev) =>
      prev.map((u) => (u.userId === userId ? { ...u, role: newRole } : u))
    );
  };

  const saveUserChanges = async () => {
    try {
      for (let i = 0; i < editedUsers.length; i++) {
        const original = combinedUsers[i];
        const edited = editedUsers[i];

        // If role has changed, update it
        if (original.role !== edited.role) {
          await axios.post(
            '/.netlify/functions/updateUserRole',
            { userId: edited.userId, newRole: edited.role },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        }

        // Update user dealerships
        await axios.post(
          '/.netlify/functions/updateUserDealerships',
          { userId: edited.userId, dealerships: edited.dealerships },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      setMessage('User data updated successfully.');
      setCombinedUsers(editedUsers);
      setIsEditingUsers(false);

      // Refresh to ensure UI is correct
      await Promise.all([fetchUsers(), fetchUserPermissions()]);
    } catch (error) {
      console.error('Error updating user data:', error);
      setMessage('Error updating user data.');
    }
  };

  /**
   * -----------------------------
   * Manage Default Dealerships
   * -----------------------------
   */
  const handleDefaultChange = (dealershipId, newDefault) => {
    setEditedDealerships((prev) =>
      prev.map((d) => (d.id === dealershipId ? { ...d, is_default: newDefault } : d))
    );
  };

  const saveDefaultDealerships = async () => {
    try {
      for (const d of editedDealerships) {
        await axios.post(
          '/.netlify/functions/updateDealershipDefault',
          { dealershipId: d.id, isDefault: d.is_default },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      setMessage('Default dealerships updated.');
      setIsEditingDefaults(false);
      fetchDealerships();
    } catch (error) {
      console.error('Error updating default dealerships:', error);
      setMessage('Error updating default dealerships.');
    }
  };

  /**
   * -----------------------------
   * Manage Role Caps (no "id" column)
   * -----------------------------
   */
  // If your table has only "role" (string PK) and "cap" (integer)
  // we use 'role' as the unique identifier.

  // For a newly added role-cap that doesn't exist yet in DB, we can track
  // them with a temporary key or something similar.
  
  // Update local state whenever user edits
  const handleRoleCapChange = (roleValue, field, newValue) => {
    setEditedRoleCaps((prev) =>
      prev.map((item) =>
        item.role === roleValue ? { ...item, [field]: newValue } : item
      )
    );
  };

  // Add a brand-new row with some placeholder for 'role'
  // We'll store a local "tempRole" to avoid collisions
  const handleAddRoleCap = () => {
    // create a random placeholder for 'role' so the array has a unique key
    const placeholder = `new_${Math.random().toString(36).substring(2)}`;
    setEditedRoleCaps((prev) => [
      ...prev,
      { role: placeholder, cap: 0, _new: true }, // _new means not yet saved to DB
    ]);
  };

  // Delete from local state if it's unsaved. If it's saved, call the server
  const handleDeleteRoleCap = async (rc) => {
    // If this is a newly added item (has _new flag), remove from local state
    if (rc._new) {
      setEditedRoleCaps((prev) => prev.filter((item) => item.role !== rc.role));
      return;
    }
    // Otherwise, call the DELETE action by role
    try {
      await axios.post(
        '/.netlify/functions/roleCaps',
        { action: 'delete', payload: { role: rc.role } },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessage('Role cap deleted successfully.');
      await fetchRoleCaps();
    } catch (error) {
      console.error('Error deleting role cap:', error);
      setMessage('Error deleting role cap.');
    }
  };

  // Save all changes: for items with _new => create, else update
  const saveRoleCaps = async () => {
    try {
      for (const rc of editedRoleCaps) {
        if (rc._new) {
          // create
          // We have a placeholder role for new items (rc.role). 
          // If user didn't rename it, that might be a problem 
          // so ensure the user typed something meaningful for role.
          if (rc.role.startsWith('new_')) {
            // This means user hasn't typed anything for "role"
            // You might want to skip or show an error
            if (!rc.cap && rc.cap !== 0) continue; 
            // or handle it differently as needed
          }
          await axios.post(
            '/.netlify/functions/roleCaps',
            { action: 'create', payload: { role: rc.role, cap: rc.cap } },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        } else {
          // update by role
          await axios.post(
            '/.netlify/functions/roleCaps',
            { action: 'update', payload: { role: rc.role, cap: rc.cap } },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        }
      }
      setMessage('Role caps updated successfully.');
      setIsEditingRoleCaps(false);
      await fetchRoleCaps();
    } catch (error) {
      console.error('Error saving role caps:', error);
      setMessage('Error saving role caps.');
    }
  };

  /**
   * -----------------------------
   * Check loading & admin role
   * -----------------------------
   */
  if (loading || isLoading) {
    return <LoadingSpinner />;
  }
  if (role?.toLowerCase() !== 'admin') {
    return <div>You do not have permission to access this page.</div>;
  }

  /**
   * -----------------------------
   * Render
   * -----------------------------
   */
  return (
    <div>
      <h1>Admin Panel</h1>
      {message && <p>{message}</p>}

      {/* MANAGE USERS */}
      <section>
        <h2>Manage Users</h2>
        {isEditingUsers ? (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Dealerships</th>
                </tr>
              </thead>
              <tbody>
                {editedUsers.map((user) => (
                  <tr key={user.userId}>
                    <td>{user.email}</td>
                    <td>
                      <select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user.userId, e.target.value)}
                      >
                        <option value="authenticated">authenticated</option>
                        <option value="admin">admin</option>
                        <option value="user">user</option>
                        <option value="pro">pro</option>
                        {/* Add more roles if relevant */}
                      </select>
                    </td>
                    <td>
                      {dealerships.map((d) => (
                        <label key={d.id} style={{ marginRight: '1rem' }}>
                          <input
                            type="checkbox"
                            checked={user.dealerships.includes(d.dealership_name)}
                            onChange={(e) =>
                              toggleUserDealership(
                                user.userId,
                                d.dealership_name,
                                e.target.checked
                              )
                            }
                          />
                          {d.dealership_name}
                        </label>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={saveUserChanges}>Save</button>
            <button
              onClick={() => {
                setIsEditingUsers(false);
                setEditedUsers(combinedUsers); // revert changes
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Dealerships</th>
                </tr>
              </thead>
              <tbody>
                {combinedUsers.map((user) => (
                  <tr key={user.userId}>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{user.dealerships.join(', ') || 'None'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setIsEditingUsers(true)}>Edit Users</button>
          </>
        )}
      </section>

      {/* MANAGE DEFAULT DEALERSHIPS */}
      <section>
        <h2>Manage Default Dealerships</h2>
        {isEditingDefaults ? (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Dealership Name</th>
                  <th>Default?</th>
                </tr>
              </thead>
              <tbody>
                {editedDealerships.map((d) => (
                  <tr key={d.id}>
                    <td>{d.dealership_name}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={d.is_default}
                        onChange={(e) => handleDefaultChange(d.id, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={saveDefaultDealerships}>Save Default Dealerships</button>
            <button
              onClick={() => {
                setIsEditingDefaults(false);
                setEditedDealerships(dealerships);
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Dealership Name</th>
                  <th>Default?</th>
                </tr>
              </thead>
              <tbody>
                {dealerships.map((d) => (
                  <tr key={d.id}>
                    <td>{d.dealership_name}</td>
                    <td>
                      <input type="checkbox" readOnly checked={d.is_default} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setIsEditingDefaults(true)}>Edit Default Dealerships</button>
          </>
        )}
      </section>

      {/* MANAGE ROLE CAPS (No ID column) */}
      <section>
        <h2>Manage Role Caps</h2>
        {isEditingRoleCaps ? (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Cap</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {editedRoleCaps.map((rc, index) => (
                  <tr key={rc.role}>
                    <td>
                      <input
                        type="text"
                        value={rc.role}
                        onChange={(e) => handleRoleCapChange(rc.role, 'role', e.target.value)}
                        // If you do not want to allow changing the role
                        // comment out the above line and keep it readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={rc.cap}
                        onChange={(e) => handleRoleCapChange(rc.role, 'cap', e.target.value)}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleDeleteRoleCap(rc)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={handleAddRoleCap}>Add Role Cap</button>
            <button onClick={saveRoleCaps}>Save</button>
            <button
              onClick={() => {
                setIsEditingRoleCaps(false);
                setEditedRoleCaps(roleCaps); // revert to original
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Cap</th>
                </tr>
              </thead>
              <tbody>
                {roleCaps.map((rc) => (
                  <tr key={rc.role}>
                    <td>{rc.role}</td>
                    <td>{rc.cap}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setIsEditingRoleCaps(true)}>Edit Role Caps</button>
          </>
        )}
      </section>
    </div>
  );
}

export default AdminPanel;
