import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PriceStatsChart = ({ data }) => {
  if (!data) return <p>No Price Stats Data</p>;

  const chartData = [
    { name: 'Avg', value: data.average_price },
    { name: 'Median', value: data.median_price },
    { name: 'Min', value: data.min_price },
    { name: 'Max', value: data.max_price },
    { name: 'Total Value', value: data.total_inventory_value },
  ];

  return (
    <div className="chart-container">
      <h4>Price Stats (Live)</h4>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PriceStatsChart;
