// TimeFilters.js
import React from 'react';

const TimeFilters = ({ selectedTimeFilter, onSelectTime }) => {
  const timeFilters = [
    { label: 'Live', value: 'Live' },
    { label: '1D', value: '1D' },
    { label: '1W', value: '1W' },
    { label: '2W', value: '2W' },
    { label: '1M', value: '1M' },
    { label: '6M', value: '6M' },
  ];

  return (
    <fieldset className="time-filter-fieldset">
      <legend>Time Filter</legend>
      <div className="time-filter-bar">
        {timeFilters.map((filter) => (
          <button
            key={filter.value}
            className={`filter-button ${selectedTimeFilter === filter.value ? 'active' : ''}`}
            onClick={() => onSelectTime(filter.value)}
          >
            {filter.label}
          </button>
        ))}
      </div>
    </fieldset>
  );
};

export default TimeFilters;
