import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

// StarRating Component: converts a numeric rating (0-5) into colored star icons.
const StarRating = ({ rating, color = "#FFD700" }) => {
  const roundedRating = Math.round(rating * 2) / 2;
  const fullStars = Math.floor(roundedRating);
  const halfStar = roundedRating - fullStars === 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <span className="star-rating">
      {Array.from({ length: fullStars }).map((_, i) => (
        <FontAwesomeIcon key={`full-${i}`} icon={faStarSolid} style={{ color }} />
      ))}
      {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />}
      {Array.from({ length: emptyStars }).map((_, i) => (
        <FontAwesomeIcon key={`empty-${i}`} icon={faStarRegular} style={{ color }} />
      ))}
    </span>
  );
};

export default StarRating;
