import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChartLine, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import StarRating from './StarRating';
import './LiveReviewsCard.module.css';

const LiveReviewsCard = ({ reviewData, selectedTimeFilter, handleInfoClick }) => {
  if (!reviewData) return <p>No review data</p>;

  // Destructure reviewData fields – adjust these names if your data structure differs.
  const {
    google_current,
    google_previous,
    google_rating_current,
    google_rating_previous,
    yelp_current,
    yelp_previous,
    yelp_rating_current,
    yelp_rating_previous,
  } = reviewData;

  // Compute delta values
  const googleDeltaCount = google_current - google_previous;
  const googleDeltaRating = google_rating_current - google_rating_previous;
  const yelpDeltaCount = yelp_current - yelp_previous;
  const yelpDeltaRating = yelp_rating_current - yelp_rating_previous;

  // Helper: round to one decimal place
  const roundToTenth = (value) => Math.round(value * 10) / 10;

  return (
    <div className="metrics-section reviews">
      <div className="section-header">
        <h4>Review Metrics</h4>
        <FontAwesomeIcon 
          icon={faInfoCircle} 
          className="info-icon" 
          onClick={(e) => { 
            e.stopPropagation(); 
            handleInfoClick(e, 'reviews'); 
          }}
          title="More info on Review Metrics"
        />
      </div>
      {/* Google Reviews Block */}
      <div className="card-metric review-metric">
        <div className="review-label">
          <FontAwesomeIcon icon={faChartLine} /> Google Reviews:
        </div>
        <div className="review-details">
          <StarRating rating={google_rating_current} />
          <span className="review-count"> {roundToTenth(google_current)}</span>
        </div>
        <div className="review-change">
          Change:&nbsp;
          <span className={googleDeltaCount < 0 ? "negative" : "positive"}>
            {googleDeltaCount >= 0 ? '+' : ''}{roundToTenth(googleDeltaCount)}
          </span> reviews,&nbsp;
          <span className={googleDeltaRating < 0 ? "negative" : "positive"}>
            {googleDeltaRating >= 0 ? '+' : ''}{roundToTenth(googleDeltaRating)}
          </span> rating
        </div>
      </div>
      {/* Yelp Reviews Block */}
      <div className="card-metric review-metric">
        <div className="review-label">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Yelp Reviews:
        </div>
        <div className="review-details">
          <StarRating rating={yelp_rating_current} />
          <span className="review-count"> {roundToTenth(yelp_current)}</span>
        </div>
        <div className="review-change">
          Change:&nbsp;
          <span className={yelpDeltaCount < 0 ? "negative" : "positive"}>
            {yelpDeltaCount >= 0 ? '+' : ''}{roundToTenth(yelpDeltaCount)}
          </span> reviews,&nbsp;
          <span className={yelpDeltaRating < 0 ? "negative" : "positive"}>
            {yelpDeltaRating >= 0 ? '+' : ''}{roundToTenth(yelpDeltaRating)}
          </span> rating
        </div>
      </div>
    </div>
  );
};

export default LiveReviewsCard;
