import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faWarehouse,
  faTruck,
  faExclamationTriangle,
  faTrash,
  // Pricing icons
  faDollarSign,
  faBalanceScale,
  faMoneyBillWave,
  // Vehicle & Performance icons
  faTachometerAlt,
  faClock,
  faCar,
  // Star icons for reviews
  faStar as faStarSolid,
  faStarHalfAlt,
  // Info icon
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';
import './DealershipTile.css';

// StarRating Component: converts a numeric rating (0-5) into colored star icons.
const StarRating = ({ rating, color = "#FFD700" }) => {
  const roundedRating = Math.round(rating * 2) / 2;
  const fullStars = Math.floor(roundedRating);
  const halfStar = roundedRating - fullStars === 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <span className="star-rating">
      {Array.from({ length: fullStars }).map((_, i) => (
        <FontAwesomeIcon key={`full-${i}`} icon={faStarSolid} style={{ color }} />
      ))}
      {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />}
      {Array.from({ length: emptyStars }).map((_, i) => (
        <FontAwesomeIcon key={`empty-${i}`} icon={faStarRegular} style={{ color }} />
      ))}
    </span>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Helper function to round numbers to one decimal place
const roundToTenth = (value) => {
  if (typeof value === 'number') {
    return Math.round(value * 10) / 10;
  }
  return value;
};

// Formats money values with thousand separators.
const formatMoney = (value) => {
  if (typeof value === 'number') {
    const rounded = Math.round(value * 10) / 10;
    return (rounded % 1 === 0)
      ? rounded.toLocaleString('en-US')
      : rounded.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  }
  return value;
};

// Formats mileage values with thousand separators (always one decimal place)
const formatMileage = (value) => {
  if (typeof value === 'number') {
    const rounded = Math.round(value * 10) / 10;
    return rounded.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  }
  return value;
};

// Helper function to extract top 3 and bottom 3 models from model_distribution object
const getTopAndBottomModels = (modelDistribution) => {
  const models = Object.entries(modelDistribution).map(([model, count]) => ({ model, count }));
  const sortedDesc = [...models].sort((a, b) => b.count - a.count);
  const topModels = sortedDesc.slice(0, 3);
  const sortedAsc = [...models].sort((a, b) => a.count - b.count);
  const bottomModels = sortedAsc.slice(0, 3);
  return { topModels, bottomModels };
};

// ModelBarChart Component using Recharts with horizontal bars
const ModelBarChart = ({ topModels, bottomModels }) => {
  const data = [
    ...topModels.map(item => ({ name: item.model, count: item.count, type: 'top' })),
    ...bottomModels.map(item => ({ name: item.model, count: item.count, type: 'bottom' }))
  ];

  if (data.length === 0) {
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height={80}>
      <BarChart
        data={data}
        layout="vertical"
        barCategoryGap="0%"
        margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
      >
        <XAxis hide type="number" domain={[0, 'dataMax']} padding={{ left: 0, right: 10 }} />
        <YAxis 
          dataKey="name" 
          type="category" 
          tick={{ fontSize: 12, dx: -5 }} 
          interval={0}
          tickMargin={5} 
          width={70}
        />
        <Tooltip />
        <Bar dataKey="count" barSize={10}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.type === 'top' ? '#8884d8' : '#82ca9d'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const DealershipTile = ({ dealerName, stats, reviewData, selectedTimeFilter, onSelect, setActiveTab }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // State for the info popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoContent, setInfoContent] = useState({ title: '', content: '' });

  const stopPropagation = (e) => e.stopPropagation();

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle info icon click to show a popover anchored to the icon
  const handleInfoClick = (event, section) => {
    setAnchorEl(event.currentTarget);
    if (section === 'inventory') {
      setInfoContent({
        title: 'Inventory Metrics Information',
        content: 'This section shows the average inventory counts, days in inventory, and mileage data for vehicles.'
      });
    } else if (section === 'pricing') {
      setInfoContent({
        title: 'Pricing Metrics Information',
        content: 'This section shows the average price, median price, and total inventory value for your vehicles.'
      });
    } else if (section === 'model') {
      setInfoContent({
        title: 'Model Metrics Information',
        content: 'This section displays information on vehicle model distributions including the top and bottom models and the most popular vehicle type.'
      });
    } else if (section === 'reviews') {
      setInfoContent({
        title: 'Review Metrics Information',
        content: 'This section shows review statistics from Google and Yelp including ratings and review count changes.'
      });
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  if (stats?.status === 'pending') {
    return (
      <>
        <div
          className="dealer-card pending"
          role="button"
          tabIndex="0"
          onClick={stopPropagation}
          onKeyDown={(e) => { if (e.key === 'Enter') stopPropagation(e); }}
        >
          <h3>{dealerName}</h3>
          <div className="pending-message">
            Pending, data will be added on next refresh
          </div>
          <div className="actions">
            <Button
              variant="contained"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                setActiveTab('Settings'); // switch to Settings tab
              }}
              className="delete-button"
            >
              Delete <FontAwesomeIcon icon={faTrash} style={{ marginLeft: '5px' }} />
            </Button>
          </div>
        </div>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
  
  // Extract review stats
  const googleCurrent = reviewData ? reviewData.google_current : 'N/A';
  const yelpCurrent = reviewData ? reviewData.yelp_current : 'N/A';
  const googleRatingCurrent = reviewData ? reviewData.google_rating_current : 'N/A';
  const yelpRatingCurrent = reviewData ? reviewData.yelp_rating_current : 'N/A';
  const googleDeltaCount = reviewData ? (reviewData.google_current - reviewData.google_previous) : 0;
  const yelpDeltaCount = reviewData ? (reviewData.yelp_current - reviewData.yelp_previous) : 0;
  const googleDeltaRating = reviewData ? (reviewData.google_rating_current - reviewData.google_rating_previous) : 0;
  const yelpDeltaRating = reviewData ? (reviewData.yelp_rating_current - reviewData.yelp_rating_previous) : 0;

  // Compute top and bottom models from model_distribution
  const { topModels, bottomModels } = stats?.distributions?.model_distribution
    ? getTopAndBottomModels(stats.distributions.model_distribution)
    : { topModels: [], bottomModels: [] };

  return (
    <div
      className="dealer-card"
      role="button"
      tabIndex="0"
      onClick={(e) => {
        if (popoverOpen) {
          e.stopPropagation();
          handlePopoverClose();
        } else {
          onSelect(dealerName, stats);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (popoverOpen) {
            e.stopPropagation();
            handlePopoverClose();
          } else {
            onSelect(dealerName, stats);
          }
        }
      }}
    >
      <h3>{dealerName}</h3>
      <div className="metrics-grid">
        {/* Inventory Metrics Section */}
        <div className="metrics-section inventory">
          <div className="section-header">
            <h4>Inventory Metrics</h4>
            <FontAwesomeIcon 
              icon={faInfoCircle} 
              className="info-icon" 
              onClick={(e) => { e.stopPropagation(); handleInfoClick(e, 'inventory'); }}
              title="More info on Inventory Metrics"
            />
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faTruck} /> 
            <span>Movement: {roundToTenth(stats?.aggregated_inventory?.avg_new_inventory_count) ?? 'N/A'}</span>
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faWarehouse} /> 
            <span>Avg Inventory: {roundToTenth(stats?.aggregated_inventory?.avg_total_inventory_count) ?? 'N/A'}</span>
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faClock} /> 
            <span>Avg Days in Inventory: {roundToTenth(stats?.price_stats?.average_days_in_inventory) ?? 'N/A'}</span>
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faTachometerAlt} /> 
            <span>Avg Mileage: {formatMileage(stats?.price_stats?.average_mileage) ?? 'N/A'}</span>
          </div>
        </div>
        {/* Pricing Metrics Section */}
        <div className="metrics-section pricing">
          <div className="section-header">
            <h4>Pricing Metrics</h4>
            <FontAwesomeIcon 
              icon={faInfoCircle} 
              className="info-icon" 
              onClick={(e) => { e.stopPropagation(); handleInfoClick(e, 'pricing'); }}
              title="More info on Pricing Metrics"
            />
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faDollarSign} /> 
            <span>Avg Price: {formatMoney(stats?.price_stats?.average_price) ?? 'N/A'}</span>
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faBalanceScale} /> 
            <span>Median Price: {formatMoney(stats?.price_stats?.median_price) ?? 'N/A'}</span>
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faMoneyBillWave} /> 
            <span>Total Value: {formatMoney(stats?.price_stats?.total_inventory_value) ?? 'N/A'}</span>
          </div>
        </div>
        {/* Model Metrics Section */}
        <div className="metrics-section model">
          <div className="section-header">
            <h4>Model Metrics</h4>
            <FontAwesomeIcon 
              icon={faInfoCircle} 
              className="info-icon" 
              onClick={(e) => { e.stopPropagation(); handleInfoClick(e, 'model'); }}
              title="More info on Model Metrics"
            />
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faCar} /> 
            <span>Vehicle Stock Hi/Low: </span>
          </div>
          <div className="card-metric">
            <ModelBarChart topModels={topModels} bottomModels={bottomModels} />
          </div>
          <div className="card-metric">
            <FontAwesomeIcon icon={faCar} /> 
            <span>Top Vehicle Type: {stats?.distributions?.most_popular_vehicle_type ?? 'N/A'}</span>
          </div>
        </div>
        {/* Reviews Section */}
        <div className="metrics-section reviews">
          <div className="section-header">
            <h4>Review Metrics</h4>
            <FontAwesomeIcon 
              icon={faInfoCircle} 
              className="info-icon" 
              onClick={(e) => { e.stopPropagation(); handleInfoClick(e, 'reviews'); }}
              title="More info on Review Metrics"
            />
          </div>
          {/* Google Reviews Block */}
          <div className="card-metric review-metric">
            <div className="review-label">
              <FontAwesomeIcon icon={faChartLine} /> Google Reviews:
            </div>
            <div className="review-details">
              <StarRating rating={googleRatingCurrent} />
              <span className="review-count"> {roundToTenth(googleCurrent)}</span>
            </div>
            <div className="review-change">
              Change:&nbsp;
              <span className={googleDeltaCount < 0 ? "negative" : "positive"}>
                {googleDeltaCount >= 0 ? '+' : ''}{roundToTenth(googleDeltaCount)}
              </span> reviews,&nbsp;
              <span className={googleDeltaRating < 0 ? "negative" : "positive"}>
                {googleDeltaRating >= 0 ? '+' : ''}{roundToTenth(googleDeltaRating)}
              </span> rating
            </div>
          </div>
          {/* Yelp Reviews Block */}
          <div className="card-metric review-metric">
            <div className="review-label">
              <FontAwesomeIcon icon={faExclamationTriangle} /> Yelp Reviews:
            </div>
            <div className="review-details">
              <StarRating rating={yelpRatingCurrent} />
              <span className="review-count"> {roundToTenth(yelpCurrent)}</span>
            </div>
            <div className="review-change">
              Change:&nbsp;
              <span className={yelpDeltaCount < 0 ? "negative" : "positive"}>
                {yelpDeltaCount >= 0 ? '+' : ''}{roundToTenth(yelpDeltaCount)}
              </span> reviews,&nbsp;
              <span className={yelpDeltaRating < 0 ? "negative" : "positive"}>
                {yelpDeltaRating >= 0 ? '+' : ''}{roundToTenth(yelpDeltaRating)}
              </span> rating
            </div>
          </div>
        </div>
      </div>

      {/* Popover for info details */}
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '10px', maxWidth: '250px' }}>
          <h4 style={{ margin: '0 0 10px 0' }}>{infoContent.title}</h4>
          <p style={{ margin: 0 }}>{infoContent.content}</p>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handlePopoverClose();
            }}
            size="small"
            style={{ marginTop: '10px' }}
          >
            Close
          </Button>
        </div>
      </Popover>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DealershipTile;
