// src/DealershipChart.jsx
import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

const DealershipChart = ({ stats, selectedTimeFilter }) => {
  if (!stats || !stats.over_time) {
    return <div>No chart data available</div>;
  }
  
  const { dates, total_inventory_count } = stats.over_time;

  // Create data array for Recharts
  const chartData = dates.map((date, index) => ({
    date,
    inventory: total_inventory_count[index],
  }));

  return (
    <ResponsiveContainer width="100%" height={100}>
      <LineChart data={chartData}>
        <XAxis dataKey="date" tick={{ fontSize: 10 }} />
        <YAxis hide={true} />
        <Tooltip formatter={(value) => `${value} listing${value > 1 ? 's' : ''}`} />
        <Line
          type="monotone"
          dataKey="inventory"
          stroke="#8884d8"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DealershipChart;
