import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AggregatedInventoryChart from './Charts/AggregatedInventoryChart';
import PriceStatsChart from './Charts/PriceStatsChart';
import DistributionsChart from './Charts/DistributionsChart';
import SalesVelocityChart from './Charts/SalesVelocityChart';
import LiveReviewsCard from './Charts/LiveReviewsCard';
import OverTimeChart from './Charts/OverTimeChart';
import ModelDistributionOverTimeChart from './Charts/ModelDistributionOverTimeChart';
import OverTimeReviewsChart from './Charts/OverTimeReviewsChart';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBoxes, 
  faDollarSign, 
  faChartPie, 
  faTachometerAlt, 
  faComments, 
  faClock, 
  faChartLine, 
  faHistory,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

const BannerWithTooltip = ({ icon, title, tooltipText }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div 
      className="chart-banner" 
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={icon} style={{ fontSize: '24px', marginRight: '8px' }} />
        <span>{title}</span>
      </div>
      <IconButton
        onClick={handleOpen}
        style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 8, color: 'white' }}
        size="medium"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            padding: '20px',
            width: '400px',
          },
        }}
      >
        <DialogTitle>Chart Information</DialogTitle>
        <DialogContent>
          <p>{tooltipText}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const DealerDetail = ({ selectedDealer, onBack, selectedTimeFilter, reviewData }) => {
  const [mode, setMode] = useState('live');

  // Reusable style object to match the provided CSS font styles.
  const buttonStyles = {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    textTransform: 'none', // Prevents MUI from capitalizing text
    mr: 2, // Margin right
  };

  return (
    <div className="dealer-detail">
      <button className="back-button" onClick={onBack}>
        &larr; Back to Overview
      </button>
      <div className='dealercardbanner'> 
        <h2>{selectedDealer.name} Dashboard</h2>
        <p>
          Detailed graphs for <strong>{selectedDealer.name}</strong> based on the "{selectedTimeFilter}" filter.
        </p>
        <Box sx={{ mb: 2 }}>
        <Button
          variant={mode === 'live' ? 'contained' : 'outlined'}
          onClick={() => setMode('live')}
          size="small"
          sx={{
            ...buttonStyles,
            backgroundColor: mode === 'live' ? '#2196f3' : 'transparent', // active color green when live
            color: mode === 'live' ? '#fff' : 'inherit', // ensures text is readable
            '&:hover': {
              backgroundColor: mode === 'live' ? '#2196f3' : 'rgba(0, 0, 0, 0.04)', // adjust hover color if needed
            },
          }}
        >
          Live
        </Button>
        <Button
          variant={mode === 'overtime' ? 'contained' : 'outlined'}
          onClick={() => setMode('overtime')}
          size="small"
          sx={{
            ...buttonStyles,
            backgroundColor: mode === 'overtime' ? '#2196f3' : 'transparent', // active color blue when overtime
            color: mode === 'overtime' ? '#fff' : 'inherit',
            '&:hover': {
              backgroundColor: mode === 'overtime' ? '#1976d2' : 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          Over Time
        </Button>
        </Box>
      </div>
      {mode === 'live' ? (
        <div className="live-mode-charts grid-container">
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faBoxes} 
              title="Aggregated Inventory (Live)" 
              tooltipText="This chart shows aggregated inventory details for the dealer in real-time." 
            />
            <div className="chart-container">
              <AggregatedInventoryChart data={selectedDealer.aggregated_inventory} />
            </div>
          </div>
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faDollarSign} 
              title="Price Stats" 
              tooltipText="This chart shows pricing statistics for the dealer's inventory." 
            />
            <div className="chart-container">
              <PriceStatsChart data={selectedDealer.price_stats} />
            </div>
          </div>
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faChartPie} 
              title="Distributions" 
              tooltipText="This chart represents the distribution of various metrics for the dealer." 
            />
            <div className="chart-container">
              <DistributionsChart data={selectedDealer.distributions} />
            </div>
          </div>
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faTachometerAlt} 
              title="Sales Velocity" 
              tooltipText="This chart shows the sales velocity, indicating how fast inventory is moving." 
            />
            <div className="chart-container">
              <SalesVelocityChart data={selectedDealer.sales_velocity} />
            </div>
          </div>
          <div className="grid-item reviews-card">
            <BannerWithTooltip 
              icon={faComments} 
              title="Live Reviews" 
              tooltipText="This card shows live customer reviews and feedback for the dealer." 
            />
            <div className="chart-container">
              <LiveReviewsCard reviewData={reviewData} selectedTimeFilter={selectedTimeFilter} />
            </div>
          </div>
        </div>
      ) : (
        <div className="overtime-mode-charts grid-container">
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faClock} 
              title="Inventory Over Time" 
              tooltipText="This chart displays how inventory levels have changed over time." 
            />
            <div className="chart-container">
              <OverTimeChart data={selectedDealer.over_time} />
            </div>
          </div>
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faChartLine} 
              title="Model Distribution Over Time" 
              tooltipText="This chart shows the change in model distribution for the dealer over time." 
            />
            <div className="chart-container">
              <ModelDistributionOverTimeChart data={selectedDealer.model_distribution_over_time} />
            </div>
          </div>
          <div className="grid-item">
            <BannerWithTooltip 
              icon={faHistory} 
              title="Over Time Reviews" 
              tooltipText="This chart outlines the trend in reviews over a selected time period." 
            />
            <div className="chart-container">
              {reviewData && reviewData.history ? (
                <OverTimeReviewsChart reviewHistory={reviewData.history} />
              ) : (
                <p>No review history available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealerDetail;
