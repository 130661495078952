import React, { useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';

const ModelMetricsGraph = ({ dealer, onRendered }) => {
  const distribution = dealer.stats?.distributions?.model_distribution;

  useEffect(() => {
    if (onRendered && distribution && Object.keys(distribution).length > 0) {
      onRendered();
    }
  }, [distribution, onRendered]);

  // Check if distribution exists and has data.
  if (!distribution || Object.keys(distribution).length === 0) {
    console.log("Dealer stats.distributions:", dealer.stats?.distributions);
    return (
      <div style={{ padding: '10px' }}>
        <p>No model performance data available.</p>
      </div>
    );
  }

  // Convert distribution object into an array.
  const dataArray = Object.keys(distribution).map((model) => ({
    model,
    value: distribution[model],
  }));

  // Sort descending and select the top 4 models.
  const sortedDesc = [...dataArray].sort(
    (a, b) => b.value - a.value || a.model.localeCompare(b.model)
  );
  const top4 = sortedDesc.slice(0, 4);

  return (
    <ResponsiveContainer width={300} height={100}>
      <BarChart
        data={top4}
        layout="vertical"
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tick={false} height={0} />
        <YAxis type="category" dataKey="model" width={100} interval={0} />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8">
          <LabelList 
            dataKey="value" 
            position="insideRight" 
            style={{ fill: '#fff', fontSize: '10px' }} 
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ModelMetricsGraph;
