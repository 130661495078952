// src/Charts/DistributionsChart.js
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DistributionsChart = ({ data }) => {
  if (!data || !data.body_style_distribution)
    return <p>No Distribution Data</p>;

  const chartData = Object.entries(data.body_style_distribution).map(
    ([style, count]) => ({ name: style, value: count })
  );
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BD4'];

  return (
    <div className="chart-container">
      <h4>Body Style Distribution (Live)</h4>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DistributionsChart;
