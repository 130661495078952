// Dashboard.js
import React, { useState, useEffect, useMemo } from 'react';
import DealershipTiles from './DealershipTiles';
import DealerDetail from './DealerDetail';
import { useAuth } from './AuthContext';
import LoginScreen from './LoginScreen';
import AddDealershipForm from './AddDealershipForm';
import './Dashboard.css';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  Chip,
  IconButton
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

// Import theme utilities from MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create your custom theme
const theme = createTheme({
  typography: {
    // Replace "YourCustomFont" with your chosen font, ensuring it's loaded in your project.
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const timeFilters = [
  { label: '1D', value: '1D' },
  { label: '1W', value: '1W' },
  { label: '2W', value: '2W' },
  { label: '1M', value: '1M' },
  { label: '6M', value: '6M' },
];

const SELECT_ALL = '__SELECT_ALL__';
const DESELECT_ALL = '__DESELECT_ALL__';

const Dashboard = ({ setActiveTab }) => {
  // Data states
  const [aggregatedDealers, setAggregatedDealers] = useState({});
  const [dealersList, setDealersList] = useState([]);
  const [reviewHistoryData, setReviewHistoryData] = useState({});
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('1M');
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [loadingAggregated, setLoadingAggregated] = useState(true);
  const [loadingDealersList, setLoadingDealersList] = useState(true);
  const [loadingReview, setLoadingReview] = useState(true);

  // Filter states for dashboard page
  const [dealerSearch, setDealerSearch] = useState('');
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [hasUserSelected, setHasUserSelected] = useState(false);

  // Modal states for login and add dealership
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAddDealership, setShowAddDealership] = useState(false);

  // Get token from AuthContext
  const { token } = useAuth();

  // Handler for Add Dealership click
  const handleAddDealershipClick = () => {
    if (!token) {
      setShowLoginModal(true);
    } else {
      setActiveTab("Settings");
    }
  };

  // Helper function to attempt a reload on error.
  const attemptReload = () => {
    const maxAttempts = 3;
    const key = 'dashboardReloadAttempts';
    let attempts = parseInt(sessionStorage.getItem(key) || '0', 10);
    if (attempts < maxAttempts) {
      attempts++;
      sessionStorage.setItem(key, attempts.toString());
      console.error(
        `Error occurred; reloading in 1 second (attempt ${attempts} of ${maxAttempts})`
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.error('Max reload attempts reached. Please reload the page manually.');
    }
  };

  // Fetch aggregated dealer statistics.
  useEffect(() => {
    const controller = new AbortController();
    setLoadingAggregated(true);
    const timer = setTimeout(() => {
      async function fetchAggregatedData() {
        try {
          const response = await fetch(
            `/.netlify/functions/dealership_statistics?limit=50&offset=0&period=${selectedTimeFilter}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : '',
              },
              signal: controller.signal,
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          sessionStorage.setItem('dashboardReloadAttempts', '0');
          // Group rows by dealership_name and pick the latest record.
          const groupedRows = {};
          data.forEach((record) => {
            const dealer = record.dealership_name;
            if (!groupedRows[dealer]) {
              groupedRows[dealer] = [];
            }
            groupedRows[dealer].push(record);
          });
          const finalData = {};
          Object.keys(groupedRows).forEach((dealer) => {
            const rows = groupedRows[dealer];
            rows.sort((a, b) => new Date(b.computed_date) - new Date(a.computed_date));
            finalData[dealer] = rows[0].aggregated_stats || {};
          });
          setAggregatedDealers(finalData);
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Aggregated data fetch aborted');
          } else {
            console.error('Error fetching aggregated data:', error);
            attemptReload();
          }
        } finally {
          setLoadingAggregated(false);
        }
      }
      fetchAggregatedData();
    }, 300);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [selectedTimeFilter, token]);

  // Fetch dealerships list.
  useEffect(() => {
    const controller = new AbortController();
    setLoadingDealersList(true);
    async function fetchDealersList() {
      try {
        const response = await fetch(
          `/.netlify/functions/dealerships?limit=100&offset=0`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDealersList(data);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Dealers list fetch aborted');
        } else {
          console.error('Error fetching dealerships list:', error);
        }
      } finally {
        setLoadingDealersList(false);
      }
    }
    fetchDealersList();
    return () => {
      controller.abort();
    };
  }, [token]);

  // Fetch review history data.
  useEffect(() => {
    const controller = new AbortController();
    setLoadingReview(true);
    const timer = setTimeout(() => {
      async function fetchReviewHistoryData() {
        try {
          const response = await fetch(
            `/.netlify/functions/dealer_review_history?period=${selectedTimeFilter}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : '',
              },
              signal: controller.signal,
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setReviewHistoryData(data);
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Review history fetch aborted');
          } else {
            console.error('Error fetching review history data:', error);
            attemptReload();
          }
        } finally {
          setLoadingReview(false);
        }
      }
      fetchReviewHistoryData();
    }, 300);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [selectedTimeFilter, token]);

  // Merge aggregated dealers with pending dealerships.
  const finalDealersData = useMemo(() => {
    const merged = { ...aggregatedDealers };
    dealersList.forEach((dealer) => {
      if (!merged[dealer.dealership_name] && dealer.status === 'pending') {
        merged[dealer.dealership_name] = { status: dealer.status, urls: dealer.urls };
      }
    });
    return merged;
  }, [aggregatedDealers, dealersList]);

  useEffect(() => {
    const dealerNames = Object.keys(finalDealersData);
    if (dealerNames.length > 0 && !hasUserSelected) {
      setSelectedDealers(dealerNames);
    }
  }, [finalDealersData, hasUserSelected]);

  // Handler functions for filter multiselect.
  const allDealerNames = Object.keys(finalDealersData);

  const handleSelectAllDealers = () => {
    setSelectedDealers(allDealerNames);
    setHasUserSelected(true);
  };

  const handleDeselectAllDealers = () => {
    setSelectedDealers([]);
    setHasUserSelected(true);
  };

  const handleDealerChange = (event) => {
    const { target: { value } } = event;
    setHasUserSelected(true);
    if (value.includes(SELECT_ALL)) {
      handleSelectAllDealers();
      return;
    }
    if (value.includes(DESELECT_ALL)) {
      handleDeselectAllDealers();
      return;
    }
    setSelectedDealers(typeof value === 'string' ? value.split(',') : value);
  };

  // Render the page title and filter element.
  const renderDealerFilters = () => (
    <div className="dealer-filters" style={{ marginBottom: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', gap: '10px' }}>
        <TextField
          label="Search Dealers"
          variant="outlined"
          size="small"
          value={dealerSearch}
          onChange={(e) => setDealerSearch(e.target.value)}
        />
        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
          <InputLabel>Filter Dealers</InputLabel>
          <Select
            multiple
            value={selectedDealers}
            onChange={handleDealerChange}
            input={<OutlinedInput label="Filter Dealers" />}
            renderValue={(selected) =>
              selected.length === 0 ? <em>All</em> : (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() =>
                        setSelectedDealers(selected.filter(item => item !== value))
                      }
                      size="small"
                    />
                  ))}
                </div>
              )
            }
          >
            <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
              <em>Select All</em>
            </MenuItem>
            <MenuItem key={DESELECT_ALL} value={DESELECT_ALL}>
              <em>Deselect All</em>
            </MenuItem>
            {allDealerNames.map((dealerName) => (
              <MenuItem key={dealerName} value={dealerName}>
                <Checkbox checked={selectedDealers.indexOf(dealerName) > -1} />
                {dealerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={() => { setSelectedDealers([]); setDealerSearch(''); }}>
          <FontAwesomeIcon icon={faRedo} />
        </IconButton>
      </div>
    </div>
  );

  // Replace your existing renderTimeFilters function.
  const renderTimeFilters = () => (
    <fieldset className="time-filter-fieldset">
      <legend>Time Filter</legend>
      <div className="time-filter-bar">
        {timeFilters.map((filter) => (
          <button
            key={filter.value}
            className={`filter-button ${selectedTimeFilter === filter.value ? 'active' : ''}`}
            onClick={() => setSelectedTimeFilter(filter.value)}
          >
            {filter.label}
          </button>
        ))}
      </div>
    </fieldset>
  );

  // Filter the dealer keys based on search text and selection.
  const allNames = Object.keys(finalDealersData);
  let filteredDealerNames = allNames;
  if (dealerSearch) {
    filteredDealerNames = filteredDealerNames.filter(name =>
      name.toLowerCase().includes(dealerSearch.toLowerCase())
    );
  }
  if (selectedDealers.length > 0) {
    filteredDealerNames = filteredDealerNames.filter(name =>
      selectedDealers.includes(name)
    );
  }
  const filteredDealersData = {};
  filteredDealerNames.forEach(name => {
    filteredDealersData[name] = finalDealersData[name];
  });

  const isLoading = loadingAggregated || loadingDealersList || loadingReview;

  return (
    // Wrap the entire Dashboard in ThemeProvider so that the custom font applies to all MUI components.
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Dashboard</h1>
      </div>
        {!selectedDealer && renderDealerFilters()}
        {renderTimeFilters()}
        {isLoading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
            <div>Loading...</div>
          </div>
        ) : (
          selectedDealer ? (
            <DealerDetail
              selectedDealer={selectedDealer}
              onBack={() => setSelectedDealer(null)}
              selectedTimeFilter={selectedTimeFilter}
              reviewData={reviewHistoryData[selectedDealer.name]}
            />
          ) : (
            <DealershipTiles
              dealersData={filteredDealersData}
              reviewHistoryData={reviewHistoryData}
              selectedTimeFilter={selectedTimeFilter}
              onSelectDealer={(dealerName, stats) => {
                setSelectedDealer({ name: dealerName, ...stats });
              }}
              onAddClick={handleAddDealershipClick}
              setActiveTab={setActiveTab}
            />
          )
        )}
        {showLoginModal && (
          <LoginScreen onClose={() => setShowLoginModal(false)} />
        )}
        {showAddDealership && (
          <AddDealershipForm onClose={() => setShowAddDealership(false)} />
        )}
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
