import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const OverTimeChart = ({ data }) => {
  if (!data || !data.dates) return <p>No Over Time Data</p>;

  const chartData = data.dates.map((date, index) => ({
    date,
    New: data.new_inventory_count[index],
    Used: data.used_inventory_count[index],
    Total: data.total_inventory_count[index],
  }));

  return (
    <div className="chart-container">
      <h4>Inventory Over Time</h4>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="New" stroke="#82ca9d" />
          <Line type="monotone" dataKey="Used" stroke="#8884d8" />
          <Line type="monotone" dataKey="Total" stroke="#ff7300" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverTimeChart;
