import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const AggregatedInventoryChart = ({ data }) => {
  if (!data) return <p>No Aggregated Inventory Data</p>;

  const chartData = [
    { name: 'New', count: data.avg_new_inventory_count },
    { name: 'Used', count: data.avg_used_inventory_count },
    { name: 'Total', count: data.avg_total_inventory_count },
  ];

  return (
    <div className="chart-container">
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  </div>

  );
};

export default AggregatedInventoryChart;
