// StockHistoryChart.js
import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StockHistoryChart = ({ dealership, make, model }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStockHistory = async () => {
      try {
        if (!dealership || !make || !model) {
          console.error('StockHistoryChart: Missing dealership, make, or model props');
          setLoading(false);
          return;
        }

        const params = new URLSearchParams();
        params.append('dealership', dealership);
        params.append('make', make);
        params.append('model', model);

        const url = `/.netlify/functions/stockHistory?${params.toString()}`;
        console.log('Fetching stock history from:', url);

        const response = await fetch(url);
        if (!response.ok) {
          console.error('Fetch error, status:', response.status);
          throw new Error('Failed to fetch stock history data');
        }
        const jsonData = await response.json();
        console.log('Fetched stock history data:', jsonData);

        setData(jsonData);
      } catch (error) {
        console.error('Error in fetchStockHistory:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStockHistory();
  }, [dealership, make, model]);

  if (loading) return <div>Loading Chart...</div>;
  if (!data || data.length === 0) {
    console.log('No data available for StockHistoryChart');
    return <div>No Data Available</div>;
  }

  // Formatter to show only month and day (e.g., "Mar 1")
  const formatDate = (tick) =>
    new Date(tick).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width="100%" height={100}>
        <LineChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="day" 
            tickFormatter={formatDate} 
            label={{ value: 'Stock', position: 'insideBottom', offset: -5 }} 
          />
          <YAxis hide={true} />
          <Tooltip 
            labelFormatter={formatDate}
            formatter={(value, name) => [value, name === 'live_count' ? 'Live Count' : name]}
            />
          <Line type="monotone" dataKey="live_count" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockHistoryChart;
