import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  useMap,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styles from './MapComponent.module.css';
import './Dashboard.css';
import { useAuth } from './AuthContext';
import Button from 'react-bootstrap/Button';
import {
  faTruck,
  faWarehouse,
  faClock,
  faTachometerAlt,
  faDollarSign,
  faBalanceScale,
  faMoneyBillWave,
  faChartLine,
  faStar as faStarSolid,
  faStarHalfAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelMetricsGraph from './ModelMetricsGraph';

// Import default export from rc-slider and its CSS.
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// Fix leaflet marker URLs.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const CheckboxOption = (props) => (
  <components.Option {...props}>
    <input
      type="checkbox"
      checked={props.isSelected}
      onChange={() => null}
      style={{ marginRight: 8 }}
    />
    <label>{props.label}</label>
  </components.Option>
);

const CustomSelect = ({ label, options, value, onChange, isMulti = false }) => {
  return (
    <div className={styles.customSelectContainer}>
      <label className={styles.customSelectLabel}>{label}</label>
      <Select
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={onChange}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={!isMulti}
        components={isMulti ? { Option: CheckboxOption } : {}}
        className={styles.customSelect}
        classNamePrefix="react-select"
      />
    </div>
  );
};

const TimeFilters = ({ selectedTime, setSelectedTime }) => {
  const timeFilters = [
    { value: '1W', label: '1W' },
    { value: '1M', label: '1M' },
    { value: '3M', label: '3M' },
    { value: '6M', label: '6M' },
    { value: '1Y', label: '1Y' },
    { value: 'All', label: 'All' },
  ];
  return (
    <div className="time-filter-bar">
      {timeFilters.map((filter) => (
        <button
          key={filter.value}
          className={`filter-button ${selectedTime === filter.value ? 'active' : ''}`}
          onClick={() => setSelectedTime(filter.value)}
        >
          {filter.label}
        </button>
      ))}
    </div>
  );
};

const formatPrice = (value) => {
  if (value === null || isNaN(value)) return 'N/A';
  return `$${Math.round(value).toLocaleString()}`;
};

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => {
    if (acc && typeof acc === 'object') {
      return acc[part];
    }
    return undefined;
  }, obj);
};

const getMetricValue = (dealer, metricKey) => {
  if (metricKey.startsWith('reviews.')) {
    const key = metricKey.split('.')[1];
    return Number(dealer.reviews?.[`${key}_current`]) || 0;
  }
  return Number(getNestedValue(dealer.stats, metricKey)) || 0;
};

const roundToTenth = (value) => {
  if (typeof value === 'number') {
    return Math.round(value * 10) / 10;
  }
  return value;
};

const ModelGraph = ({ dealer, onRendered }) => {
  return <ModelMetricsGraph dealer={dealer} onRendered={onRendered} />;
};

const StarRating = ({ rating, color = "#FFD700" }) => {
  const roundedRating = Math.round(rating * 2) / 2;
  const fullStars = Math.floor(roundedRating);
  const halfStar = roundedRating - fullStars === 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
  return (
    <span className={styles.starRating}>
      {Array.from({ length: fullStars }).map((_, i) => (
        <FontAwesomeIcon key={`full-${i}`} icon={faStarSolid} style={{ color }} />
      ))}
      {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />}
      {Array.from({ length: emptyStars }).map((_, i) => (
        <FontAwesomeIcon key={`empty-${i}`} icon={faStarRegular} style={{ color }} />
      ))}
    </span>
  );
};

const ReviewsMetrics = ({ reviews }) => {
  const googleCurrent = reviews ? reviews.google_current : 'N/A';
  const yelpCurrent = reviews ? reviews.yelp_current : 'N/A';
  const googleRatingCurrent = reviews ? reviews.google_rating_current : 'N/A';
  const yelpRatingCurrent = reviews ? reviews.yelp_rating_current : 'N/A';
  const googleDeltaCount = reviews ? (reviews.google_current - reviews.google_previous) : 0;
  const yelpDeltaCount = reviews ? (reviews.yelp_current - reviews.yelp_previous) : 0;
  return (
    <div className={styles.reviewsContainer}>
      <div className={styles.reviewCard}>
        <div className={styles.popupStat}>
          <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 5 }} />
          <span>Google Reviews: {roundToTenth(googleCurrent)}</span>
        </div>
        <div className={styles.popupStat}>
          <StarRating rating={googleRatingCurrent} />
        </div>
        <div className={styles.popupReviews}>
          Change:{' '}
          <span className={googleDeltaCount < 0 ? styles.negative : styles.positive}>
            {googleDeltaCount >= 0 ? '+' : ''}
            {roundToTenth(googleDeltaCount)}
          </span>{' '}
          reviews
        </div>
      </div>
      <div className={styles.reviewCard}>
        <div className={styles.popupStat}>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 5 }} />
          <span>Yelp Reviews: {roundToTenth(yelpCurrent)}</span>
        </div>
        <div className={styles.popupStat}>
          <StarRating rating={yelpRatingCurrent} />
        </div>
        <div className={styles.popupReviews}>
          Change:{' '}
          <span className={yelpDeltaCount < 0 ? styles.negative : styles.positive}>
            {yelpDeltaCount >= 0 ? '+' : ''}
            {roundToTenth(yelpDeltaCount)}
          </span>{' '}
          reviews
        </div>
      </div>
    </div>
  );
};

// -------------------------
// STAT SECTIONS FOR THE POPUP CAROUSEL
// -------------------------
const statSections = [
  {
    id: 'inventory',
    label: 'Inventory Metrics',
    getMetrics: (dealer) => [
      {
        id: 'aggregated_inventory.avg_new_inventory_count',
        label: 'Movement',
        value: getMetricValue(dealer, 'aggregated_inventory.avg_new_inventory_count'),
        icon: faTruck,
      },
      {
        id: 'aggregated_inventory.avg_total_inventory_count',
        label: 'Avg Inventory',
        value: getMetricValue(dealer, 'aggregated_inventory.avg_total_inventory_count'),
        icon: faWarehouse,
      },
      {
        id: 'price_stats.average_days_in_inventory',
        label: 'Avg Days in Inventory',
        value: getMetricValue(dealer, 'price_stats.average_days_in_inventory'),
        icon: faClock,
      },
      {
        id: 'price_stats.average_mileage',
        label: 'Avg Mileage',
        value: getMetricValue(dealer, 'price_stats.average_mileage'),
        icon: faTachometerAlt,
      },
    ],
  },
  {
    id: 'pricing',
    label: 'Pricing Metrics',
    getMetrics: (dealer) => [
      {
        id: 'price_stats.average_price',
        label: 'Avg Price',
        value: formatPrice(getMetricValue(dealer, 'price_stats.average_price')),
        icon: faDollarSign,
      },
      {
        id: 'price_stats.median_price',
        label: 'Median Price',
        value: formatPrice(getMetricValue(dealer, 'price_stats.median_price')),
        icon: faBalanceScale,
      },
      {
        id: 'price_stats.total_inventory_value',
        label: 'Total Value',
        value: formatPrice(getMetricValue(dealer, 'price_stats.total_inventory_value')),
        icon: faMoneyBillWave,
      },
    ],
  },
  {
    id: 'model',
    label: 'Model Metrics',
    getMetrics: (dealer) => [
      {
        id: 'model_graph',
        label: 'Model Performance',
        isGraph: true,
      },
    ],
  },
  {
    id: 'reviews',
    label: 'Review Metrics',
    getMetrics: (dealer) => [
      {
        id: 'reviews.google',
        label: 'Google Reviews',
        value: getMetricValue(dealer, 'reviews.google'),
        icon: faChartLine,
      },
      {
        id: 'reviews.yelp',
        label: 'Yelp Reviews',
        value: getMetricValue(dealer, 'reviews.yelp'),
        icon: faExclamationTriangle,
      },
    ],
  },
];

// -------------------------
// FILTER SLIDER DEFINITIONS
// (No filters for model metrics since it is a chart.)
// -------------------------
const inventoryFilterMetrics = [
  { id: 'aggregated_inventory.avg_new_inventory_count', label: 'Movement', icon: faTruck },
  { id: 'aggregated_inventory.avg_total_inventory_count', label: 'Avg Inventory', icon: faWarehouse },
  { id: 'price_stats.average_days_in_inventory', label: 'Avg Days in Inventory', icon: faClock },
  { id: 'price_stats.average_mileage', label: 'Avg Mileage', icon: faTachometerAlt },
];

const pricingFilterMetrics = [
  { id: 'price_stats.average_price', label: 'Avg Price', icon: faDollarSign },
  { id: 'price_stats.median_price', label: 'Median Price', icon: faBalanceScale },
  { id: 'price_stats.total_inventory_value', label: 'Total Value', icon: faMoneyBillWave },
];

const reviewFilterMetrics = [
  { id: 'reviews.google', label: 'Google Reviews', icon: faChartLine },
  { id: 'reviews.yelp', label: 'Yelp Reviews', icon: faExclamationTriangle },
];

// Helper to compute absolute range for a given metric.
const computeFilterState = (metricId, dealers) => {
  const values = dealers
    .map((d) => getMetricValue(d, metricId))
    .filter((v) => v !== null && !isNaN(v));
  const minVal = Math.min(...values);
  const maxVal = Math.max(...values);
  return { value: [minVal, maxVal], min: minVal, max: maxVal };
};

// -------------------------
// Reusable RangeSlider component (using rc-slider in range mode)
// -------------------------
const RangeSlider = ({ label, sliderState, onChange, icon, isCurrency = false }) => {
  // Helper function to format the value based on type.
  const formatValue = (value) => {
    return isCurrency ? formatPrice(value) : roundToTenth(value);
  };

  return (
    <div className={styles.rangeSlider}>
      <div className={styles.sliderHeader}>
        <div className={styles.sliderLabel}>
          {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />}
          {label}
        </div>
        <div className={styles.sliderValues}>
          <span>{formatValue(sliderState.value[0])}</span>
          <span className={styles.dashSeparator}> – </span>
          <span>{formatValue(sliderState.value[1])}</span>
        </div>
      </div>
      <Slider
        range
        min={sliderState.min}
        max={sliderState.max}
        step={1}
        value={sliderState.value}
        onChange={onChange}
      />
    </div>
  );
};

// -------------------------
// StatCarousel component for the popup.
// It uses local state (passed in from DealerMarker) to show the current stat section
// with left/right arrows.
// -------------------------
const StatCarousel = ({ dealer, localStatSection, setLocalStatSection, updatePopup }) => {
  const currentSection = statSections[localStatSection] || statSections[0];
  const arrowStyle = {
    fontSize: '24px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    borderRadius: '50%',
    border: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  };
  const handlePrev = (e) => {
    e.stopPropagation();
    let newIndex = localStatSection - 1;
    if (newIndex < 0) newIndex = statSections.length - 1;
    setLocalStatSection(newIndex);
  };
  const handleNext = (e) => {
    e.stopPropagation();
    let newIndex = localStatSection + 1;
    if (newIndex >= statSections.length) newIndex = 0;
    setLocalStatSection(newIndex);
  };
  return (
    <div className={styles.carouselContainer} style={{ position: 'relative', height: '180px' }}>
      <div className={styles.carouselHeader}>
        <span>{currentSection.label}</span>
      </div>
      <Button
        variant="secondary"
        onClick={handlePrev}
        className={styles.carouselArrow}
        style={{ ...arrowStyle, left: '0' }}
      >
        {'<'}
      </Button>
      <div
        className={styles.carouselContent}
        style={{ height: '130px', margin: '0 40px', textAlign: 'left', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}
      >
        {currentSection.id === 'reviews' ? (
          <ReviewsMetrics reviews={dealer.reviews} />
        ) : currentSection.id === 'model' ? (
          // Render the chart for model metrics.
          <div className={styles.carouselGraph}>
            <ModelGraph dealer={dealer} onRendered={updatePopup} />
          </div>
        ) : (
          currentSection.getMetrics(dealer).map((metric, index) => (
            <div key={index} className={styles.carouselMetric}>
              <FontAwesomeIcon icon={metric.icon} style={{ marginRight: 5 }} />
              <span>
                {metric.label}:{' '}
                {typeof metric.value === 'number'
                  ? roundToTenth(metric.value)
                  : metric.value || 'N/A'}
              </span>
            </div>
          ))
        )}
      </div>
      <Button
        variant="secondary"
        onClick={handleNext}
        className={styles.carouselArrow}
        style={{ ...arrowStyle, right: '0' }}
      >
        {'>'}
      </Button>
    </div>
  );
};

// -------------------------
// DealerMarker: renders a marker for each dealer.
// It uses a local carousel state (initially synced with the global popup stat section)
// and interpolates the marker color based on the inventory "Movement" metric.
// -------------------------
const DealerMarker = ({
  dealer,
  globalStatSection,
  minMetric,
  maxMetric,
  zoomThreshold = 10,
  tolerance = 50,
}) => {
  const map = useMap();
  const markerRef = useRef();
  const popupRef = useRef();
  const [localStatSection, setLocalStatSection] = useState(globalStatSection);
  useEffect(() => {
    setLocalStatSection(globalStatSection);
  }, [globalStatSection]);
  const updatePopup = () => {
    if (popupRef && popupRef.current) {
      popupRef.current.update();
    }
  };
  useEffect(() => {
    const handleZoom = () => {
      if (!markerRef.current) return;
      const currentZoom = map.getZoom();
      const markerLatLng = markerRef.current.getLatLng();
      const markerPoint = map.latLngToContainerPoint(markerLatLng);
      const mapSize = map.getSize();
      const isWithinTolerance =
        markerPoint.x > tolerance &&
        markerPoint.x < mapSize.x - tolerance &&
        markerPoint.y > tolerance &&
        markerPoint.y < mapSize.y - tolerance;
      if (currentZoom >= zoomThreshold && isWithinTolerance) {
        markerRef.current.openPopup();
      } else {
        markerRef.current.closePopup();
      }
    };
    map.on('zoomend moveend resize', handleZoom);
    handleZoom();
    return () => {
      map.off('zoomend moveend resize', handleZoom);
    };
  }, [map, zoomThreshold, tolerance]);
  // Use the inventory "Movement" metric for marker color.
  const primaryMetricKey = 'aggregated_inventory.avg_new_inventory_count';
  const rawHighlightValue = getMetricValue(dealer, primaryMetricKey);
  const highlightValue = rawHighlightValue !== undefined ? Number(rawHighlightValue) : 0;
  const interpolateColor = (value, min, max) => {
    if (value == null || max === min) return '#FFEDA0';
    const normalized = (value - min) / (max - min);
    const start = { r: 255, g: 237, b: 160 };
    const end = { r: 128, g: 0, b: 38 };
    const r = Math.round(start.r + normalized * (end.r - start.r));
    const g = Math.round(start.g + normalized * (end.g - start.g));
    const b = Math.round(start.b + normalized * (end.b - start.b));
    return `rgb(${r}, ${g}, ${b})`;
  };
  const markerColor = interpolateColor(highlightValue, minMetric, maxMetric);
  return (
    <CircleMarker
      ref={markerRef}
      center={[Number(dealer.latitude), Number(dealer.longitude)]}
      radius={10}
      fillColor={markerColor}
      color={markerColor}
      fillOpacity={0.8}
      stroke={false}
    >
      <Popup
        ref={popupRef}
        autoPan={false}
        autoClose={false}
        closeOnClick={false}
        minWidth={350}
        maxWidth={600}
      >
        <div className={styles.popupContent}>
          <div className={styles.cardHeader}>
            <h3 style={{ margin: 0 }}>{dealer.dealership_name}</h3>
          </div>
          <StatCarousel
            dealer={dealer}
            localStatSection={localStatSection}
            setLocalStatSection={setLocalStatSection}
            updatePopup={updatePopup}
          />
        </div>
      </Popup>
    </CircleMarker>
  );
};

// -------------------------
// MapComponent: loads dealer data, computes filter ranges, renders global filters,
// and then displays a map with filtered DealerMarkers.
// -------------------------
const MapComponent = () => {
  const [dealerships, setDealerships] = useState([]);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const [selectedTime, setSelectedTime] = useState('All');
  // Global filter for which popup stat section is displayed.
  const [globalStatSection, setGlobalStatSection] = useState(0);
  const statSectionOptions = statSections.map((section, index) => ({
    value: index,
    label: section.label,
  }));
  // Filter states for each metric (each holds an object with current range and absolute bounds)
  const [inventoryFilters, setInventoryFilters] = useState({});
  const [pricingFilters, setPricingFilters] = useState({});
  const [reviewFilters, setReviewFilters] = useState({});
  const attemptReload = () => {
    const maxAttempts = 3;
    const key = 'mapReloadAttempts';
    let attempts = parseInt(sessionStorage.getItem(key) || '0', 10);
    if (attempts < maxAttempts) {
      attempts++;
      sessionStorage.setItem(key, attempts.toString());
      console.error(
        `Error fetching map data; reloading in 1 second (attempt ${attempts} of ${maxAttempts})`
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.error('Max map reload attempts reached. Please reload the page manually.');
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const timer = setTimeout(() => {
      const fetchData = async () => {
        try {
          const resDealerships = await fetch('/.netlify/functions/dealerships', {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          });
          if (!resDealerships.ok) throw new Error('Failed to fetch dealerships');
          const dealershipData = await resDealerships.json();
          const resStats = await fetch('/.netlify/functions/dealership_statistics', {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          });
          if (!resStats.ok) throw new Error('Failed to fetch dealership statistics');
          const statsData = await resStats.json();
          const resReviews = await fetch('/.netlify/functions/dealer_review_history', {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          });
          if (!resReviews.ok) throw new Error('Failed to fetch dealership review history');
          const reviewsData = await resReviews.json();
          const combinedData = dealershipData.map((dealer) => {
            const dealerStats =
              statsData.find((stat) => stat.dealership_name === dealer.dealership_name) || {};
            const dealerReviews = reviewsData[dealer.dealership_name] || {};
            return {
              ...dealer,
              stats: dealerStats.aggregated_stats ? dealerStats.aggregated_stats : dealerStats,
              reviews: dealerReviews,
            };
          });
          const activeDealerships = combinedData.filter((dealer) => dealer.status === 'active');
          setDealerships(activeDealerships);
          const allOptions = activeDealerships.map((d) => ({
            value: d.dealership_name,
            label: d.dealership_name,
          }));
          setDealerOptions(allOptions);
          setSelectedDealers(allOptions);
          setLoading(false);
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted in MapComponent');
          } else {
            console.error('Error fetching data:', error);
            attemptReload();
          }
          setLoading(false);
        }
      };
      fetchData();
    }, 300);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [token]);
  // When dealerships load, compute the absolute range for every filter metric.
  useEffect(() => {
    if (dealerships.length > 0) {
      const newInventoryFilters = {};
      inventoryFilterMetrics.forEach((metric) => {
        newInventoryFilters[metric.id] = computeFilterState(metric.id, dealerships);
      });
      setInventoryFilters(newInventoryFilters);
      const newPricingFilters = {};
      pricingFilterMetrics.forEach((metric) => {
        newPricingFilters[metric.id] = computeFilterState(metric.id, dealerships);
      });
      setPricingFilters(newPricingFilters);
      const newReviewFilters = {};
      reviewFilterMetrics.forEach((metric) => {
        newReviewFilters[metric.id] = computeFilterState(metric.id, dealerships);
      });
      setReviewFilters(newReviewFilters);
    }
  }, [dealerships]);
  // Apply filters from selected dealers and each slider.
  let filteredDealerships = dealerships.filter((dealer) =>
    selectedDealers.some((opt) => opt.value === dealer.dealership_name)
  );
  Object.keys(inventoryFilters).forEach((key) => {
    const filter = inventoryFilters[key];
    filteredDealerships = filteredDealerships.filter((dealer) => {
      const val = getMetricValue(dealer, key);
      return val >= filter.value[0] && val <= filter.value[1];
    });
  });
  Object.keys(pricingFilters).forEach((key) => {
    const filter = pricingFilters[key];
    filteredDealerships = filteredDealerships.filter((dealer) => {
      const val = getMetricValue(dealer, key);
      return val >= filter.value[0] && val <= filter.value[1];
    });
  });
  Object.keys(reviewFilters).forEach((key) => {
    const filter = reviewFilters[key];
    filteredDealerships = filteredDealerships.filter((dealer) => {
      const val = getMetricValue(dealer, key);
      return val >= filter.value[0] && val <= filter.value[1];
    });
  });
  // Sort by the inventory "Movement" metric.
  const sortedDealerships = filteredDealerships.slice().sort((a, b) => {
    const aValue = getMetricValue(a, 'aggregated_inventory.avg_new_inventory_count');
    const bValue = getMetricValue(b, 'aggregated_inventory.avg_new_inventory_count');
    return bValue - aValue;
  });
  // For marker color interpolation, use the inventory "Movement" metric.
  const inventoryMetricValues = sortedDealerships
    .map((dealer) => getMetricValue(dealer, 'aggregated_inventory.avg_new_inventory_count'))
    .filter((val) => val !== null);
  const minMetric = inventoryMetricValues.length > 0 ? Math.min(...inventoryMetricValues) : 0;
  const maxMetric = inventoryMetricValues.length > 0 ? Math.max(...inventoryMetricValues) : 0;
  return (
    <div className={styles.mapComponentContainer}>
      <div className={styles.filtersContainer}>
        <TimeFilters selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
        <CustomSelect
          label="Select Dealerships:"
          options={dealerOptions}
          value={selectedDealers}
          onChange={(options) => setSelectedDealers(options || [])}
          isMulti
        />
        <CustomSelect
          label="Select Popup Stat Section:"
          options={statSectionOptions}
          value={statSectionOptions.find((opt) => opt.value === globalStatSection)}
          onChange={(option) => setGlobalStatSection(option.value)}
        />
        <div className={styles.filterGroup}>
          <h4>Inventory Metrics Filters</h4>
          {inventoryFilterMetrics.map((metric) =>
            inventoryFilters[metric.id] ? (
              <RangeSlider
                key={metric.id}
                label={metric.label}
                sliderState={inventoryFilters[metric.id]}
                icon={metric.icon}
                onChange={(newRange) =>
                  setInventoryFilters((prev) => ({
                    ...prev,
                    [metric.id]: { ...prev[metric.id], value: newRange },
                  }))
                }
              />
            ) : null
          )}
        </div>
        <div className={styles.filterGroup}>
          <h4>Pricing Metrics Filters</h4>
          {pricingFilterMetrics.map((metric) =>
            pricingFilters[metric.id] ? (
              <RangeSlider
                key={metric.id}
                label={metric.label}
                sliderState={pricingFilters[metric.id]}
                icon={metric.icon}
                isCurrency={true}
                onChange={(newRange) =>
                  setPricingFilters((prev) => ({
                    ...prev,
                    [metric.id]: { ...prev[metric.id], value: newRange },
                  }))
                }
              />
            ) : null
          )}
        </div>
        <div className={styles.filterGroup}>
          <h4>Review Metrics Filters</h4>
          {reviewFilterMetrics.map((metric) =>
            reviewFilters[metric.id] ? (
              <RangeSlider
                key={metric.id}
                label={metric.label}
                sliderState={reviewFilters[metric.id]}
                icon={metric.icon}
                onChange={(newRange) =>
                  setReviewFilters((prev) => ({
                    ...prev,
                    [metric.id]: { ...prev[metric.id], value: newRange },
                  }))
                }
              />
            ) : null
          )}
        </div>
      </div>
      <div className={styles.mapContainer}>
        {loading ? (
          <div className={styles.mapLoadingSpinner}>
            <div className={styles.spinner}></div>
            <p>Loading map data...</p>
          </div>
        ) : (
          <MapContainer center={[39.5, -98.35]} zoom={4} className={styles.leafletMap}>
            <TileLayer
              attribution='&copy; <a href="https://carto.com/">Carto</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
            />
            <MapBounds markers={sortedDealerships} />
            {sortedDealerships.map((dealer) => (
              <DealerMarker
                key={dealer.id || dealer.dealership_name}
                dealer={dealer}
                globalStatSection={globalStatSection}
                minMetric={minMetric}
                maxMetric={maxMetric}
                zoomThreshold={6}
              />
            ))}
          </MapContainer>
        )}
      </div>
    </div>
  );
};

const MapBounds = ({ markers }) => {
  const map = useMap();
  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((m) => [Number(m.latitude), Number(m.longitude)])
      );
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers, map]);
  return null;
};

export default MapComponent;
