// src/DealerCards.jsx
import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Checkbox,
  IconButton,
  Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
// Removed TimeFilters import as it is no longer used
import DealershipChart from './DealershipChart';
import DealershipTile from './DealershipTile';
import AddDealershipTile from './AddDealershipTile';

const SELECT_ALL = '__SELECT_ALL__';
const DESELECT_ALL = '__DESELECT_ALL__';

const DealerCards = ({
  dealersData,
  setSelectedDealer,
  onAddDealership,
  onOpenExportDialog
}) => {
  // Force the timeframe for dealer tiles to 1M.
  const localTimeFilter = '1M';

  const [dealerSearch, setDealerSearch] = useState('');
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [hasUserSelected, setHasUserSelected] = useState(false);

  useEffect(() => {
    const dealerNames = Object.keys(dealersData);
    if (dealerNames.length > 0 && !hasUserSelected) {
      setSelectedDealers(dealerNames);
    }
  }, [dealersData, hasUserSelected]);

  const allDealerNames = Object.keys(dealersData);

  const handleSelectAllDealers = () => {
    setSelectedDealers(allDealerNames);
    setHasUserSelected(true);
  };

  const handleDeselectAllDealers = () => {
    setSelectedDealers([]);
    setHasUserSelected(true);
  };

  const handleResetDealers = () => {
    setDealerSearch('');
    setSelectedDealers(allDealerNames);
    setHasUserSelected(false);
  };

  const handleDealerChange = (event) => {
    const {
      target: { value },
    } = event;
    setHasUserSelected(true);
    if (value.includes(SELECT_ALL)) {
      handleSelectAllDealers();
      return;
    }
    if (value.includes(DESELECT_ALL)) {
      handleDeselectAllDealers();
      return;
    }
    setSelectedDealers(typeof value === 'string' ? value.split(',') : value);
  };

  let filteredDealers = allDealerNames.filter((dealer) =>
    dealer.toLowerCase().includes(dealerSearch.toLowerCase())
  );

  if (selectedDealers.length > 0) {
    filteredDealers = filteredDealers.filter((dealer) =>
      selectedDealers.includes(dealer)
    );
  }

  filteredDealers = filteredDealers.filter((dealer) => {
    const dealerObj = dealersData[dealer];
    if (dealerObj.status === 'pending') return true;
    return (
      dealerObj.aggregatedStats &&
      dealerObj.aggregatedStats.over_time &&
      dealerObj.aggregatedStats.over_time.total_inventory_count &&
      dealerObj.aggregatedStats.over_time.total_inventory_count.length > 0
    );
  });

  const getStatsChangeDelta = (aggregatedStats) => {
    if (localTimeFilter === 'Live') return null;
    if (!aggregatedStats || !aggregatedStats.over_time) return null;
    const { dates, total_inventory_count } = aggregatedStats.over_time;
    if (!dates || dates.length === 0 || !total_inventory_count || total_inventory_count.length === 0)
      return null;

    const currentDateStr = dates[dates.length - 1];
    const currentCount = total_inventory_count[total_inventory_count.length - 1];
    const currentDate = new Date(currentDateStr);

    if (localTimeFilter === '1D') {
      const previousDay = new Date(currentDate);
      previousDay.setDate(currentDate.getDate() - 1);
      const previousDayStr = previousDay.toISOString().slice(0, 10);
      const index = dates.findIndex((dateStr) => dateStr === previousDayStr);
      let previousCount = null;
      if (index !== -1) {
        previousCount = total_inventory_count[index];
      } else if (total_inventory_count.length >= 2) {
        previousCount = total_inventory_count[total_inventory_count.length - 2];
      }
      if (previousCount === null) return null;
      const rawChange = currentCount - previousCount;
      const percentChange = previousCount ? (rawChange / previousCount) * 100 : null;
      return { rawChange, percentChange };
    } else {
      const filterDaysMapping = {
        '1W': 7,
        '2W': 14,
        '1M': 30,
        '6M': 180,
      };
      const daysAgo = filterDaysMapping[localTimeFilter];
      if (!daysAgo) return null;
      const targetDate = new Date(currentDate);
      targetDate.setDate(currentDate.getDate() - daysAgo);
      let closestIndex = -1;
      let minDiff = Infinity;
      dates.forEach((dateStr, idx) => {
        const d = new Date(dateStr);
        const diff = Math.abs(d - targetDate);
        if (diff < minDiff) {
          minDiff = diff;
          closestIndex = idx;
        }
      });
      if (closestIndex === -1) return null;
      const previousCount = total_inventory_count[closestIndex];
      const rawChange = currentCount - previousCount;
      const percentChange = previousCount ? (rawChange / previousCount) * 100 : null;
      return { rawChange, percentChange };
    }
  };

  return (
    <div>
      <h1>Raw Data</h1>
      <div
        className="dealer-filters"
        style={{
          marginBottom: '20px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
          <TextField
            label="Search Dealers"
            variant="outlined"
            size="small"
            value={dealerSearch}
            onChange={(e) => setDealerSearch(e.target.value)}
          />
          <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
            <InputLabel>Filter Dealers</InputLabel>
            <Select
              multiple
              value={selectedDealers}
              onChange={handleDealerChange}
              input={<OutlinedInput label="Filter Dealers" />}
              renderValue={(selected) =>
                selected.length === 0 ? (
                  <em>All</em>
                ) : (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => {
                          setSelectedDealers(selected.filter((item) => item !== value));
                        }}
                        size="small"
                      />
                    ))}
                  </div>
                )
              }
            >
              <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
                <em>Select All</em>
              </MenuItem>
              <MenuItem key={DESELECT_ALL} value={DESELECT_ALL}>
                <em>Deselect All</em>
              </MenuItem>
              {allDealerNames.map((dealerName) => (
                <MenuItem key={dealerName} value={dealerName}>
                  <Checkbox checked={selectedDealers.indexOf(dealerName) > -1} />
                  {dealerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton onClick={handleResetDealers} size="small" aria-label="Reset dealers">
            <FontAwesomeIcon icon={faRedo} />
          </IconButton>
          {/* Export Data button placed inside the dealer filter bar */}
          <Button variant="contained" color="primary" onClick={onOpenExportDialog}>
            Export Data
          </Button>
        </div>
      </div>
      <div className="dealer-cards-grid">
        {filteredDealers.map((dealerName) => {
          const dealerObj = dealersData[dealerName];
          if (dealerObj.status === 'pending') {
            return (
              <DealershipTile
                key={dealerName}
                dealerName={dealerName}
                stats={{ status: 'pending', urls: dealerObj.urls }}
                reviewData={null}
                selectedTimeFilter={localTimeFilter}
                onSelect={() => {}}
              />
            );
          }
          const aggregatedStats = dealerObj.aggregatedStats;
          const totalInventoryCount =
            aggregatedStats?.over_time?.total_inventory_count;
          const currentCount =
            totalInventoryCount && totalInventoryCount.length > 0
              ? totalInventoryCount[totalInventoryCount.length - 1]
              : 0;
          const delta = getStatsChangeDelta(aggregatedStats);
          let deltaDisplay = null;
          if (delta && localTimeFilter !== 'Live') {
            const { rawChange, percentChange } = delta;
            const color = rawChange > 0 ? 'green' : rawChange < 0 ? 'red' : 'black';
            deltaDisplay = (
              <p style={{ color }}>
                {rawChange > 0 ? '+' : ''}
                {rawChange} inventory (
                {percentChange !== null
                  ? percentChange > 0
                    ? '+' + percentChange.toFixed(1)
                    : percentChange.toFixed(1)
                  : '0'}
                %)
              </p>
            );
          }
          return (
            <div
              key={dealerName}
              className="dealer-card"
              onClick={() => setSelectedDealer(dealerName)}
              style={{ cursor: 'pointer' }}
            >
              <h3>{dealerName}</h3>
              <p>{currentCount} inventory</p>
              {deltaDisplay}
              <DealershipChart
                stats={aggregatedStats}
                selectedTimeFilter={localTimeFilter}
              />
            </div>
          );
        })}
        <AddDealershipTile onClick={onAddDealership} />
      </div>
    </div>
  );
};

export default DealerCards;
