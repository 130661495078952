// src/Data.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import DealerCards from './DealerCards';
import DealerDataTable from './DealerDataTable';
import useDealersData from './useDealersData';
import AddDealershipForm from './AddDealershipForm';
import LoginScreen from './LoginScreen';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button
} from '@mui/material';
import { flattenObject } from './helpers';
import './Data.css';



// Import icons for export format options
import TableChartIcon from '@mui/icons-material/TableChart';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Import XLSX for Excel export (ensure you've installed it via "npm install xlsx")
import * as XLSX from 'xlsx';

const Data = ({ setActiveTab }) => {
  const { token } = useAuth();
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('1M');
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);

  // Fetch aggregated dealer data (using selectedTimeFilter, which drives aggregated stats)
  const { dealersData, loading } = useDealersData(selectedTimeFilter, token);

  // Handler for Add Dealership tile click.
  const handleAddDealershipClick = () => {
    if (!token) {
      setShowLoginModal(true);
    } else {
      setActiveTab('Settings');
    }
  };

  return (
    <div className="dashboard-container">
      {loading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {selectedDealer ? (
            <DealerDataTable
              selectedDealer={selectedDealer}
              dealersData={dealersData}
              selectedTimeFilter={selectedTimeFilter}
              setSelectedDealer={setSelectedDealer}
              setSelectedTimeFilter={setSelectedTimeFilter}
              token={token}
            />
          ) : (
            <DealerCards
              dealersData={dealersData}
              setSelectedDealer={setSelectedDealer}
              onAddDealership={handleAddDealershipClick}
              onOpenExportDialog={() => setShowExportDialog(true)}
            />
          )}
        </>
      )}
      {showAddForm && <AddDealershipForm onClose={() => setShowAddForm(false)} />}
      {showLoginModal && <LoginScreen onClose={() => setShowLoginModal(false)} />}
      {showExportDialog && (
        <TopLevelExportDialog
          open={showExportDialog}
          onClose={() => setShowExportDialog(false)}
          token={token}
          dealersData={dealersData}
        />
      )}
    </div>
  );
};

const TopLevelExportDialog = ({ open, onClose, token, dealersData }) => {
  console.log("TopLevelExportDialog token:", token);

  // Get available dealer names from dealersData
  const allDealerNames = Object.keys(dealersData);
  const [selectedExportDealers, setSelectedExportDealers] = useState(allDealerNames);
  const [exportOption, setExportOption] = useState('live');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [exportConditions, setExportConditions] = useState([]);
  const [exportMakes, setExportMakes] = useState([]);
  const [exportModels, setExportModels] = useState([]);
  const [availableConditions, setAvailableConditions] = useState([]);
  const [availableMakes, setAvailableMakes] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [exporting, setExporting] = useState(false);

  // New state: export file format (csv, excel, json)
  const [exportFormat, setExportFormat] = useState('csv');

  // Default file name: "MotorMetrics-ListingsData-[Date].[ext]"
  const today = new Date().toISOString().slice(0, 10);
  const [exportFileName, setExportFileName] = useState(`MotorMetrics-ListingsData-${today}.csv`);

  // Update file extension when exportFormat changes using a functional update to avoid dependency on exportFileName
  useEffect(() => {
    setExportFileName(prevName => {
      const ext = exportFormat === 'excel' ? 'xlsx' : exportFormat === 'json' ? 'json' : 'csv';
      const base = prevName.lastIndexOf('.') !== -1 ? prevName.substring(0, prevName.lastIndexOf('.')) : prevName;
      return `${base}.${ext}`;
    });
  }, [exportFormat]);

  // Helper: fetch listings for selected dealers (using a default time filter of 1M)
  const fetchListingsForDealers = useCallback(async () => {
    let allListings = [];
    console.log("Fetching listings with token:", token);
    for (let dealer of selectedExportDealers) {
      try {
        const res = await fetch(
          `/.netlify/functions/listings?dealership=${encodeURIComponent(dealer)}&timeFilter=1M`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            cache: 'no-cache',
          }
        );
        if (res.ok) {
          const data = await res.json();
          allListings = allListings.concat(data);
        }
      } catch (err) {
        console.error(`Error fetching listings for dealer ${dealer}:`, err);
      }
    }
    return allListings;
  }, [selectedExportDealers, token]);

  // When the dialog opens or selected dealers change, load available filter options from the listings.
  useEffect(() => {
    if (open && token) {
      (async () => {
        const listings = await fetchListingsForDealers();
        const conditions = [...new Set(listings.map(l => l.vehicle_condition).filter(Boolean))];
        const makes = [...new Set(listings.map(l => l.make).filter(Boolean))];
        const models = [...new Set(listings.map(l => l.model).filter(Boolean))];
        setAvailableConditions(conditions);
        setAvailableMakes(makes);
        setAvailableModels(models);
      })();
    }
  }, [open, token, fetchListingsForDealers]);

  // Utility: Convert data to CSV and trigger download
  const exportToCsv = (data, filename) => {
    if (!data || !data.length) {
      console.warn("No data to export");
      return;
    }
    const headers = Object.keys(flattenObject(data[0])).filter(h => h !== 'text' && h !== 'id');
    const csvRows = [];
    csvRows.push(headers.join(','));
    data.forEach(row => {
      const flatRow = flattenObject(row);
      const values = headers.map(header => {
        let val = flatRow[header] !== null && flatRow[header] !== undefined ? flatRow[header].toString() : '';
        val = val.replace(/"/g, '""');
        return `"${val}"`;
      });
      csvRows.push(values.join(','));
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Handle export: fetch listings for each selected dealer, filter them per exportOption and additional filters, then export in the selected format.
  const handleExportClick = async () => {
    setExporting(true);
    let allListings = [];
    for (let dealer of selectedExportDealers) {
      try {
        const res = await fetch(
          `/.netlify/functions/listings?dealership=${encodeURIComponent(dealer)}&timeFilter=1M`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' }
          }
        );
        if (res.ok) {
          const data = await res.json();
          allListings = allListings.concat(data);
        }
      } catch (err) {
        console.error(`Error fetching listings for dealer ${dealer}:`, err);
      }
    }

    // Filter listings based on exportOption and additional filters.
    let filteredForExport = allListings.filter(listing => {
      if (exportOption === 'live') {
        if (listing.last_date_seen !== null) return false;
      } else if (exportOption === 'this_week') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const now = new Date();
        const day = now.getDay();
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - day);
        if (listingDate < startOfWeek) return false;
      } else if (exportOption === 'this_month') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        if (listingDate < startOfMonth) return false;
      } else if (exportOption === 'this_year') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        if (!listingDate) return false;
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        if (listingDate < startOfYear) return false;
      } else if (exportOption === 'custom') {
        const listingDate = listing.listing_date ? new Date(listing.listing_date) : null;
        const start = customStartDate ? new Date(customStartDate) : null;
        const end = customEndDate ? new Date(customEndDate) : null;
        if (start && listingDate && listingDate < start) return false;
        if (end && listingDate && listingDate > end) return false;
      }
      if (exportConditions.length > 0 && !exportConditions.includes(listing.vehicle_condition)) {
        return false;
      }
      if (exportMakes.length > 0 && !exportMakes.includes(listing.make)) {
        return false;
      }
      if (exportModels.length > 0 && !exportModels.includes(listing.model)) {
        return false;
      }
      return true;
    });

    // Export based on selected format
    if (exportFormat === 'csv') {
      exportToCsv(filteredForExport, exportFileName);
    } else if (exportFormat === 'json') {
      const jsonString = JSON.stringify(filteredForExport, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = exportFileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (exportFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredForExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listings');
      XLSX.writeFile(wb, exportFileName);
    }

    setExporting(false);
    onClose();
  };

  // Base style for export option container
  const optionBaseStyle = {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '8px',
    borderRadius: '4px',
    position: 'relative',
    flex: 1,
    margin: '0 8px'
  };

  // Get additional style if option is selected
  const getOptionStyle = (option) => {
    if (exportFormat === option) {
      return { backgroundColor: '#d0eaff', border: '2px solid blue' };
    }
    return { backgroundColor: '#f5f5f5' };
  };

  // Render export option component
  const renderExportOption = (option, icon, label) => (
    <div
      style={{ ...optionBaseStyle, ...getOptionStyle(option) }}
      onClick={() => setExportFormat(option)}
    >
      {icon}
      <div>{label}</div>
      {exportFormat === option && (
        <CheckCircleIcon style={{ color: 'blue', position: 'absolute', top: 4, right: 4 }} />
      )}
    </div>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Export Listings Data</DialogTitle>
      <DialogContent dividers>
        {/* Dealer selection with "Select All" option */}
        <div style={{ margin: '0', fontWeight: 'bold', fontSize: '16px' }}>Select Dealers</div>

        <FormControl fullWidth margin="normal">
          <InputLabel>Select Dealers</InputLabel>
          <Select
            multiple
            value={selectedExportDealers}
            onChange={(e) => {
              const value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
              if (value.includes("__SELECT_ALL__")) {
                setSelectedExportDealers(allDealerNames);
              } else {
                setSelectedExportDealers(value);
              }
            }}
            input={<OutlinedInput label="Select Dealers" />}
            renderValue={(selected) => selected.join(', ')}
          >
            <MenuItem key="select_all" value="__SELECT_ALL__">
              <Checkbox checked={selectedExportDealers.length === allDealerNames.length} />
              <em>Select All</em>
            </MenuItem>
            {allDealerNames.map((dealer) => (
              <MenuItem key={dealer} value={dealer}>
                <Checkbox checked={selectedExportDealers.indexOf(dealer) > -1} />
                {dealer}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Export option selection */}
        <div style={{ margin: '12px 0 0px 0', fontWeight: 'bold', fontSize: '16px' }}>Select Timeframe</div>
        <FormControl component="fieldset" margin="normal">
          <RadioGroup row value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
            <FormControlLabel value="live" control={<Radio />} label="Live" />
            <FormControlLabel value="this_week" control={<Radio />} label="This Week" />
            <FormControlLabel value="this_month" control={<Radio />} label="This Month" />
            <FormControlLabel value="this_year" control={<Radio />} label="This Year" />
            <FormControlLabel value="custom" control={<Radio />} label="Custom Date" />
          </RadioGroup>
        </FormControl>

        {exportOption === 'custom' && (
          <>
            <TextField
              label="Start Date & Time"
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
            <TextField
              label="End Date & Time"
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
          </>
        )}

        {/* Additional filters */}
        <div style={{ margin: '0', fontWeight: 'bold', fontSize: '16px' }}>Select Data Filters</div>
        <FormControl fullWidth margin="normal">
          <InputLabel>Vehicle Condition</InputLabel>
          <Select
            multiple
            value={exportConditions}
            onChange={(e) =>
              setExportConditions(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Vehicle Condition" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableConditions.map((cond) => (
              <MenuItem key={cond} value={cond}>
                <Checkbox checked={exportConditions.indexOf(cond) > -1} />
                {cond}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Make</InputLabel>
          <Select
            multiple
            value={exportMakes}
            onChange={(e) =>
              setExportMakes(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Make" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableMakes.map((make) => (
              <MenuItem key={make} value={make}>
                <Checkbox checked={exportMakes.indexOf(make) > -1} />
                {make}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Model</InputLabel>
          <Select
            multiple
            value={exportModels}
            onChange={(e) =>
              setExportModels(
                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
              )
            }
            input={<OutlinedInput label="Model" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {availableModels.map((model) => (
              <MenuItem key={model} value={model}>
                <Checkbox checked={exportModels.indexOf(model) > -1} />
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Export Format Selection */}
        <div style={{ margin: '16px 0', fontWeight: 'bold', fontSize: '16px' }}>Select Export Type</div>
        <div className="export-format-selector" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '16px' }}>
          {renderExportOption(
            'excel',
            <TableChartIcon style={{ fontSize: '40px' }} />,
            'Excel'
          )}
          {renderExportOption(
            'csv',
            <DescriptionIcon style={{ fontSize: '40px' }} />,
            'CSV'
          )}
          {renderExportOption(
            'json',
            <CodeIcon style={{ fontSize: '40px' }} />,
            'JSON'
          )}
        </div>

        {/* Export File Name */}
        <TextField
          label="Export File Name"
          fullWidth
          margin="normal"
          value={exportFileName}
          onChange={(e) => setExportFileName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={exporting}>Cancel</Button>
        <Button onClick={handleExportClick} variant="contained" color="primary" disabled={exporting}>
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Data;
