import React from 'react';
import DealershipTile from './DealershipTile';
import AddDealershipTile from './AddDealershipTile';
import './DealershipTiles.css';

const DealershipTiles = ({
  dealersData,
  reviewHistoryData,
  selectedTimeFilter,
  onSelectDealer,
  onAddClick,
  setActiveTab  // add the prop here
}) => {
  return (
    <div className="dealer-cards-grid">
      {Object.entries(dealersData).map(([dealerName, stats]) => {
        const reviewInfo = reviewHistoryData[dealerName];
        return (
          <DealershipTile
            key={dealerName}
            dealerName={dealerName}
            stats={stats}
            reviewData={reviewInfo}
            selectedTimeFilter={selectedTimeFilter}
            onSelect={onSelectDealer}
            setActiveTab={setActiveTab} // pass it down here
          />
        );
      })}
      <AddDealershipTile onClick={onAddClick} />
    </div>
  );
};

export default DealershipTiles;
