import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SalesVelocityChart = ({ data }) => {
  if (!data || !data.velocity_by_model)
    return <p>No Sales Velocity Data</p>;

  const chartData = Object.entries(data.velocity_by_model).map(
    ([model, velocity]) => ({ model, velocity })
  );

  return (
    <div className="chart-container">
      <h4>Sales Velocity (Live)</h4>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="model" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="velocity" fill="#ff8042" />
        </BarChart>
      </ResponsiveContainer>
      <div className="velocity-extremes">
        <p>
          <strong>Fastest Selling Model:</strong> {data.fastest_selling_model || 'N/A'}
        </p>
        <p>
          <strong>Slowest Selling Model:</strong> {data.slowest_selling_model || 'N/A'}
        </p>
      </div>
    </div>
  );
};

export default SalesVelocityChart;
