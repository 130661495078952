// src/Sidebar.js
import React from 'react';
import logo from './images/mm-logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import {
  faBalanceScale,
  faComments,
  faSignOutAlt,
  faUserShield,
  faMapMarkedAlt,
  faCarSide,
  faDatabase,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({
  user,
  signOut,
  role,
  activeTab,
  setActiveTab,
  onLoginClick,
}) => {
  return (
    <div className="sidebar">
      <img src={logo} alt="Logo" className="logo" />
      <div className="user-info">
        {user ? (
          <>
            <div>
              Welcome, {user.user_metadata?.full_name?.trim() ? user.user_metadata.full_name : 'User'}
              <br />
            </div>
            <button className="logout-button" onClick={signOut}>
              <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
            </button>
          </>
        ) : (
          <button className="login-button" onClick={onLoginClick}>
            <FontAwesomeIcon icon={faUserShield} /> Log In
          </button>
        )}
      </div>
      <div className="sidebar-content">
        <div className="tabs">
          {/* Dashboard Tab */}
          <button
            className={activeTab === 'Dashboard' ? 'tab active' : 'tab'}
            onClick={() => setActiveTab('Dashboard')}
            aria-label="Dashboard Tab"
          >
            <FontAwesomeIcon icon={faBalanceScale} style={{ marginRight: '10px' }} />
            Dashboard
          </button>
          {/* Data Tab */}
          <button
            className={activeTab === 'Raw Data' ? 'tab active' : 'tab'}
            onClick={() => setActiveTab('Raw Data')}
            aria-label="Data Tab"
          >
            <FontAwesomeIcon icon={faDatabase} style={{ marginRight: '10px' }} />
            Raw Data
          </button>
          {/* Map Tab */}
          <button
            className={activeTab === 'Dealer Map' ? 'tab active' : 'tab'}
            onClick={() => setActiveTab('Dealer Map')}
            aria-label="Dealer Map Tab"
          >
            <FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '10px' }} />
            Dealer Map
          </button>
          {/* Inventory Map Tab */}
          <button
            className={activeTab === 'Model Map' ? 'tab active' : 'tab'}
            onClick={() => setActiveTab('Model Map')}
            aria-label="Model Map Tab"
          >
            <FontAwesomeIcon icon={faCarSide} style={{ marginRight: '10px' }} />
            Model Map
          </button>
          {/* Feedback Tab */}
          <button
            className={activeTab === 'Feedback' ? 'tab active' : 'tab'}
            onClick={() => setActiveTab('Feedback')}
            aria-label="Feedback Tab"
          >
            <FontAwesomeIcon icon={faComments} style={{ marginRight: '10px' }} />
            Feedback
          </button>
          {user && (
            <button
              className={activeTab === 'Settings' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Settings')}
              aria-label="Settings Tab"
            >
              <FontAwesomeIcon icon={faCog} style={{ marginRight: '10px' }} />
              Settings
            </button>
          )}
          {/* Admin Tab (if applicable) */}
          {role === 'admin' && (
            <button
              className={activeTab === 'Admin' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Admin')}
              aria-label="Admin Tab"
            >
              <FontAwesomeIcon icon={faUserShield} style={{ marginRight: '10px' }} />
              Admin
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
