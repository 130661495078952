// UserSettings.js
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Box,
  Alert,
  CircularProgress,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './UserSettings.css';
import AddDealershipForm from './AddDealershipForm';

const UserSettings = () => {
  const { token, user } = useAuth();
  const [userDealers, setUserDealers] = useState([]);
  const [allowedCap, setAllowedCap] = useState(null); // Fetched from backend
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Fetch allowed cap based on user role.
  useEffect(() => {
    const fetchAllowedCap = async () => {
      const role = user?.user_metadata?.role || 'user';
      try {
        const response = await fetch(`/.netlify/functions/getRoleCap?role=${role}`, {
          headers: { Authorization: token ? `Bearer ${token}` : '' },
        });
        if (!response.ok) throw new Error('Failed to fetch role cap');
        const data = await response.json();
        setAllowedCap(data.cap === -1 ? Infinity : data.cap);
      } catch (error) {
        console.error('Error fetching allowed cap:', error);
        setAllowedCap(0);
      }
    };

    if (user) {
      fetchAllowedCap();
    }
  }, [token, user]);

  // Fetch user's dealerships.
  useEffect(() => {
    const fetchUserDealers = async () => {
      try {
        const response = await fetch('/.netlify/functions/dealerships?limit=100&offset=0', {
          headers: { Authorization: token ? `Bearer ${token}` : '' },
        });
        if (!response.ok) throw new Error('Failed to fetch your dealerships');
        const data = await response.json();
        setUserDealers(data);
      } catch (error) {
        console.error('Error fetching user dealerships:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDealers();
  }, [token]);

  if (loading || allowedCap === null) {
    return (
      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const nonDefaultCount = userDealers.filter(dealer => !dealer.is_default).length;

  // Sort dealers so that default ones are listed first.
  const sortedDealers = [...userDealers].sort((a, b) => {
    if (a.is_default === b.is_default) return 0;
    return a.is_default ? -1 : 1;
  });

  const handleRemoveDealer = (dealerName, isDefault) => {
    if (isDefault) return;
    const updated = userDealers.filter(
      dealer => dealer.dealership_name !== dealerName
    );
    setUserDealers(updated);
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    setMessage('');
    setMessageType('');
    try {
      const dealerships = userDealers.map(dealer => dealer.dealership_name);
      const payload = {
        userId: user?.id,
        dealerships,
      };
      const response = await fetch('/.netlify/functions/updateUserDealerships', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update user dealerships');
      }
      setMessage('User dealerships updated successfully!');
      setMessageType('success');
    } catch (error) {
      console.error('Error updating user dealerships:', error);
      setMessage('Error updating user dealerships.');
      setMessageType('error');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Paper sx={{ maxWidth: 800, width: '100%', p: 3 }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '2em',
            fontWeight: 'bold',
            mb: 2,
          }}
        >
          User Settings
        </Typography>
        {message && (
          <Alert severity={messageType || 'success'} sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        {/* Prominent Dealership Counter */}
        <Typography
          variant="h7"
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
            padding: '8px',
            borderRadius: '4px',
            display: 'inline-block',
            mb: 2,
          }}
        >
          Dealerships: {nonDefaultCount}/{allowedCap === Infinity ? 'Unlimited' : allowedCap}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Your Subscribed Dealerships
        </Typography>
        {userDealers.length === 0 ? (
          <Typography variant="body2">
            You are not subscribed to any dealerships.
          </Typography>
        ) : (
          <List>
            {sortedDealers.map(dealer => (
              <ListItem key={dealer.dealership_name} divider>
                <ListItemText
                  primary={dealer.dealership_name}
                  secondary={dealer.is_default ? 'Default - cannot remove' : ''}
                />
                <ListItemSecondaryAction>
                  {!dealer.is_default && (
                    <IconButton
                      edge="end"
                      onClick={() =>
                        handleRemoveDealer(dealer.dealership_name, dealer.is_default)
                      }
                      sx={{ color: 'red' }}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsAddModalOpen(true)}
          >
            Add New Dealership
          </Button>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            disabled={saving}
            aria-label="Save Changes"
            fullWidth
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
        {isAddModalOpen && (
          <AddDealershipForm
            onClose={() => setIsAddModalOpen(false)}
            currentCount={nonDefaultCount}
            allowedCap={allowedCap}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UserSettings;
