// AddDealershipForm.js
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';

const AddDealershipForm = ({ onClose, currentCount, allowedCap, existingDealers }) => {
  const { token } = useAuth();
  const [dealershipName, setDealershipName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [hasSelected, setHasSelected] = useState(false);
  const [urls, setUrls] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [options, setOptions] = useState([]);

  // Debounced fetch for dealership suggestions.
  useEffect(() => {
    const debouncedFetch = debounce(async (query) => {
      if (!query) {
        setOptions([]);
        return;
      }
      try {
        const response = await fetch(`/.netlify/functions/getDealershipSuggestions?query=${encodeURIComponent(query)}`);
        const data = await response.json();
        setOptions(data.suggestions || []);
      } catch (err) {
        console.error('Error fetching suggestions', err);
      }
    }, 300);

    if (!hasSelected) {
      debouncedFetch(inputValue);
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [inputValue, hasSelected]);

  // Handle selection from autocomplete.
  const handleAutocompleteChange = async (event, newValue) => {
    if (newValue && typeof newValue === 'object' && newValue.place_id) {
      setHasSelected(true);
      try {
        const response = await fetch(`/.netlify/functions/getPlaceDetails?place_id=${newValue.place_id}`);
        const data = await response.json();
        const officialName = (data.name || newValue.name).split(',')[0].trim();
        setDealershipName(officialName);
        setInputValue(officialName);
      } catch (error) {
        console.error('Error fetching place details:', error);
        const fallbackName = newValue.name.split(',')[0].trim();
        setDealershipName(fallbackName);
        setInputValue(fallbackName);
      }
    } else if (typeof newValue === 'string') {
      setHasSelected(false);
      setDealershipName(newValue);
      setInputValue(newValue);
    } else {
      setHasSelected(false);
      setDealershipName('');
      setInputValue('');
    }
  };

  // Update input field while typing.
  const handleInputChange = (event, newInputValue) => {
    if (newInputValue === '') {
      setHasSelected(false);
    }
    if (!hasSelected) {
      setInputValue(newInputValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess(false);
    console.log('AddDealershipForm handleSubmit - currentCount:', currentCount, 'allowedCap:', allowedCap);
    if (currentCount >= allowedCap) {
      setError(`You have reached your dealership limit of ${allowedCap}.`);
      return;
    }
  
    if (!dealershipName.trim() || !urls.trim()) {
      setError('Please fill in all fields.');
      return;
    }
    console.log('currentCount:', currentCount, 'allowedCap:', allowedCap);

    // Sanitize the dealership name.
    const sanitizedDealershipName = dealershipName.split(',')[0].trim();
  
    // Check if the dealership already exists.
    if (existingDealers && Array.isArray(existingDealers)) {
      const duplicateDealer = existingDealers.find(
        (dealer) =>
          dealer.dealership_name.toLowerCase() === sanitizedDealershipName.toLowerCase()
      );
      if (duplicateDealer) {
        if (duplicateDealer.is_default) {
          setError("Error, can't add, is default dealer.");
        } else {
          setError("Dealership already added.");
        }
        return;
      }
    }
  
    const urlArray = urls
      .split(',')
      .map((url) => url.trim())
      .filter((url) => url !== '');
  
    if (urlArray.length === 0) {
      setError('Please enter at least one valid URL.');
      return;
    }
  
    setLoading(true);
    try {
      const response = await fetch('/.netlify/functions/addDealership', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ dealership_name: sanitizedDealershipName, urls: urlArray }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        // Handle 409 Conflict specifically.
        if (response.status === 409) {
          setError("Dealership already added.");
          setLoading(false);
          return;
        }
        // If the error message from backend contains "already exists", treat it as success.
        if (data.error && data.error.toLowerCase().includes('already exists')) {
          setSuccess(true);
          setTimeout(() => window.location.reload(), 2000);
          return;
        } else {
          throw new Error(data.error || 'Failed to add dealership');
        }
      }
      setSuccess(true);
      setDealershipName('');
      setInputValue('');
      setUrls('');
      setHasSelected(false);
      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Modal open onClose={onClose} aria-labelledby="add-dealership-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add a New Dealership
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>Dealership added successfully!</Alert>}
        <form onSubmit={handleSubmit}>
          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            value={inputValue}
            onChange={handleAutocompleteChange}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Dealership Name"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <TextField
            label="Website URLs (comma-separated)"
            fullWidth
            variant="outlined"
            margin="normal"
            value={urls}
            onChange={(e) => setUrls(e.target.value)}
            required
            helperText="Enter multiple URLs separated by commas."
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Add Dealership'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddDealershipForm;
