// src/App.js
import React, { useState } from 'react';
import './App.css';
import Sidebar from './Sidebar';
import FeedbackComponent from './FeedbackComponent';
import MapComponent from './MapComponent';
import InventoryMapComponent from './InventoryMapComponent';
import AdminPanel from './AdminPanel';
import LoginScreen from './LoginScreen';
import Dashboard from './Dashboard';
import Data from './Data';
import { useAuth } from './AuthContext';
import UserSettings from './UserSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create your custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function App() {
  const { user, signOut, role, loading } = useAuth();
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [showLoginScreen, setShowLoginScreen] = useState(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Sidebar
          user={user}
          signOut={signOut}
          role={role}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLoginClick={() => setShowLoginScreen(true)}
        />

        <div className="main-content-container">
          <div className="main-content">
            {activeTab === 'Dashboard' && <Dashboard setActiveTab={setActiveTab} />}
            {activeTab === 'Raw Data' && <Data setActiveTab={setActiveTab} />}
            {activeTab === 'Feedback' && <FeedbackComponent />}
            {activeTab === 'Dealer Map' && <MapComponent />}
            {activeTab === 'Model Map' && <InventoryMapComponent />}
            {activeTab === 'Settings' && <UserSettings />}
            {activeTab === 'Admin' && role === 'admin' && <AdminPanel />}
          </div>
        </div>

        {showLoginScreen && (
          <LoginScreen onClose={() => setShowLoginScreen(false)} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
