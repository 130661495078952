// src/useDealersData.js
import { useState, useEffect } from 'react';

const useDealersData = (selectedTimeFilter, token) => {
  const [dealersData, setDealersData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    async function fetchDashboardData() {
      setLoading(true);
      try {
        // Fetch dealership statistics (aggregated data)
        const statsResponse = await fetch(
          `/.netlify/functions/dealership_statistics?period=${selectedTimeFilter}`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          }
        );
        if (!statsResponse.ok)
          throw new Error('Statistics network error');
        const statsData = await statsResponse.json();

        // Fetch dealerships data
        const dealershipsResponse = await fetch(
          `/.netlify/functions/dealerships?limit=100&offset=0`,
          {
            headers: { Authorization: token ? `Bearer ${token}` : '' },
            signal: controller.signal,
          }
        );
        if (!dealershipsResponse.ok)
          throw new Error('Dealerships network error');
        const dealershipsData = await dealershipsResponse.json();

        // Create a mapping of dealership statistics by dealership name
        const statsByDealer = {};
        statsData.forEach((stat) => {
          // Normalize the dealership name if needed
          statsByDealer[stat.dealership_name] = stat.aggregated_stats;
        });

        // Merge dealerships and statistics
        const mergedData = {};
        dealershipsData.forEach((dealer) => {
          const dealerName = dealer.dealership_name;
          mergedData[dealerName] = {
            aggregatedStats: statsByDealer[dealerName] || null,
            status: dealer.status,
            urls: dealer.urls,
          };
        });
        // Include any statistics for dealerships not returned from the dealerships endpoint
        Object.keys(statsByDealer).forEach((dealerName) => {
          if (!mergedData[dealerName]) {
            mergedData[dealerName] = {
              aggregatedStats: statsByDealer[dealerName],
              status: 'active',
            };
          }
        });
        setDealersData(mergedData);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching dashboard data:', error);
        }
      } finally {
        setLoading(false);
      }
    }
    fetchDashboardData();
    return () => controller.abort();
  }, [selectedTimeFilter, token]);

  return { dealersData, loading };
};

export default useDealersData;
